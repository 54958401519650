import { TemplateResult, unsafeCSS } from 'lit';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { property } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENList } from '../list/list';
import { ENListItem } from '../list-item/list-item';
import { ENSideMenu } from '../side-menu/side-menu';
import styles from './side-menu-item.scss';

/**
 * @slot - The component content
 */
export class ENSideMenuItem extends ENElement {
  static el = 'en-side-menu-item';

  private elementMap = register({
    elements: [
      [ENSideMenuItem.el, ENSideMenuItem],
      [ENList.el, ENList],
      [ENListItem.el, ENListItem],
      [ENIconChevronDown.el, ENIconChevronDown],
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }
  /**
   * Side nav item URL
   *
   * @required
   */
  @property()
  href: string;

  /**
   * Orientation
   * - **vertical** renders the icon on top of the text and narrows the sidebar
   */
  @property()
  orientation?: 'vertical';

  /**
   * Append to the class name. Used for passing in utility classes
   */
  @property()
  styleModifier?: string;

  /**
   * Active state of the item
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * Current state of the location in the product
   */
  @property({ type: Boolean })
  isCurrent?: boolean;

  /**
   * Disable state of the item
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Collapsed state of the menu
   */
  @property({ type: Boolean })
  isCollapsed?: boolean;

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  /**
   * Connected callback lifecycle
   * 1) Add event listeners
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * Disconnected callback lifecycle
   * 1) Remove event listeners
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * Handle click outside the component
   * 1) Close the show hide panel on click outside only if isCollpased id true.
   * 2) If the nav is already closed then we don't care about outside clicks and we
   * can bail early
   * 3) By the time a user clicks on the page the shadowRoot will almost certainly be
   * defined, but TypeScript isn't that trusting and sees this.shadowRoot as possibly
   * undefined. To work around that we'll check that we have a shadowRoot (and a
   * rendered .host) element here to appease the TypeScript compiler. This should never
   * actually be shown or run for a human end user.
   * 4) Check to see if we clicked inside the active navigation item
   * 5) If the navigation is active and we've clicked outside of the nav then it should
   * be closed.
   */
  handleOnClickOutside(event: MouseEvent) {
    if (this.isCollapsed) {
      /* 2 */
      if (!this.isActive) {
        return;
      }

      /* 3 */
      if (!this.shadowRoot?.host) {
        throw Error('Could not determine navigation context during click handler');
      }

      /* 4 */
      const didClickInside = event.composedPath().includes(this.shadowRoot.host);

      /* 5 */
      if (this.isActive && !didClickInside) {
        this.isActive = false;
      }
    }
  }

  /**
   * Toggle active state of side menu item
   * 1) Remove isActive state from all sibling elements
   * 2) Toggle active state of element selected
   */
  clickHandler(e: MouseEvent) {
    e.preventDefault();
    if (this.parentNode) {
      const sideMenuItems = this.parentNode.querySelectorAll(this.elementMap.get(ENSideMenuItem.el));
      sideMenuItems.forEach((element: any) => {
        if (element !== this) {
          element.isActive = false; /* 1 */
        }
      });
    }
    this.isActive = !this.isActive; /* 2 */
  }

  /**
   * Click on list item without dropdown
   * 1) On click, remove all isCurrent properties from all side-menu items
   * 2) Add isCurrent to the item clicked
   */
  linkClickHandler() {
    const navItems = this.parentNode.querySelectorAll(this.elementMap.get(ENSideMenuItem.el));

    navItems.forEach((element: ENSideMenuItem) => {
      if (element !== this) {
        element.isCurrent = false; /* 1 */
      }

      const allListItems = element.querySelectorAll(this.elementMap.get(ENListItem.el));
      allListItems.forEach((item: ENListItem) => {
        if (item.isCurrent === true) {
          item.isCurrent = false; /* 1 */
        }
      });
    });

    this.isCurrent = true; /* 2 */
  }

  /**
   * List item selected from the side menu item item panel
   * 1) Get the event target. If that is the `en-list-item` or the parent is (for spans inside),
   * then remove all isCurrent states from nav items
   * 2) Set the selected item to true
   * 3) If the item has children, then remove all `isCurrent` states from those items if another item is selected
   * 4) If nested children within dropdown, find all parent node list items and set `isCurrent` if that item is selected.
   */
  itemSelected(evt: MouseEvent) {
    const subListElement = evt.target as ENListItem;
    const allSideMenuItems = this.parentNode.querySelectorAll(this.elementMap.get(ENSideMenuItem.el));

    if (
      ((subListElement.outerHTML.includes(this.elementMap.get(ENListItem.el)) && !subListElement.querySelector(this.elementMap.get(ENList.el))) ||
        (subListElement.parentElement.outerHTML.includes(this.elementMap.get(ENListItem.el)) && !subListElement.parentElement.querySelector(this.elementMap.get(ENList.el)))) &&
      !subListElement.isDisabled &&
      !subListElement.querySelector(this.elementMap.get(ENList.el))
    ) {
      const navItems = this.parentNode.querySelectorAll(this.elementMap.get(ENSideMenuItem.el));
      navItems.forEach((element: ENSideMenuItem) => {
        /* 1 */
        if (element !== this) {
          element.isCurrent = false;
        }

        allSideMenuItems.forEach((element) => {
          const allListItems = element.querySelectorAll(this.elementMap.get(ENListItem.el));

          allListItems.forEach((item: ENListItem) => {
            if (item.isCurrent === true) {
              item.isCurrent = false; /* 3 */
            }
          });
        });
      });

      this.isCurrent = true; /* 2 */

      if (subListElement.closest(this.elementMap.get(ENListItem.el))) {
        const selectedItem = subListElement.closest(this.elementMap.get(ENListItem.el)) as ENListItem;
        selectedItem.isCurrent = true;
        let newSubListElement = selectedItem as ENListItem;

        while (newSubListElement.parentElement.nodeName !== this.elementMap.get(ENSideMenuItem.el).toUpperCase()) {
          newSubListElement = <ENListItem>newSubListElement.parentElement;

          if (newSubListElement.nodeName === this.elementMap.get(ENListItem.el).toUpperCase()) {
            newSubListElement.isCurrent = true; /* 4 */
          }
        }
      }

      const thisParent = this.parentElement;
      if ((<ENSideMenu>thisParent).isCollapsed) {
        this.isActive = false;
      }
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-side-menu__item', {
      'en-is-collapsed': this.isCollapsed === true,
      'en-is-active': this.isActive === true,
      'en-is-current': this.isCurrent === true,
      'en-is-disabled': this.isDisabled === true,
      'en-c-side-menu__item--vertical': this.orientation === 'vertical'
    });

    return html`
      <li role="listitem" class="${componentClassNames}">
        <div class="en-c-side-menu__item-inner">
          ${this.href
            ? html`
                <a class="en-c-side-menu__link" @click=${this.linkClickHandler} href=${this.href}>
                  ${this.slotNotEmpty('before') &&
                  html`
                    <div class="en-c-side-menu__icon">
                      <slot name="before"></slot>
                    </div>
                  `}
                  <div class="en-c-side-menu__text">
                    <slot></slot>
                  </div>
                  ${this.slotNotEmpty('after') &&
                  html`
                    <div class="en-c-side-menu__after">
                      <slot name="after"></slot>
                    </div>
                  `}
                </a>
              `
            : html`
                <button
                  class="en-c-side-menu__link"
                  ?disabled=${this.isDisabled}
                  @click=${this.clickHandler}
                  aria-expanded=${this.isActive === true ? true : false}
                >
                  ${
                    this.slotNotEmpty('before') &&
                    html`
                      <div class="en-c-side-menu__icon">
                        <slot name="before"></slot>
                      </div>
                    `
                  }
                  <div class="en-c-side-menu__text">
                    <slot></slot>
                  </div>

                  <div class="en-c-side-menu__item-right"></div>
                  ${
                    this.slotNotEmpty('after') &&
                    html`
                      <div class="en-c-side-menu__after">
                        <slot name="after"></slot>
                      </div>
                    `
                  }
                  ${this.slotNotEmpty('items') && html` <${this.iconChevronDownEl} class="en-c-side-menu__items-icon"></${this.iconChevronDownEl}> `}
          </div>
                </button>
              `}
        </div>
        ${this.slotNotEmpty('items') &&
        html`
          <div class="en-c-side-menu__item-panel" aria-hidden=${!this.isActive}>
            <slot name="items" @click=${this.itemSelected}></slot>
          </div>
        `}
      </li>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENSideMenuItem.el) === undefined) {
  customElements.define(ENSideMenuItem.el, ENSideMenuItem);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-side-menu-item': ENSideMenuItem;
  }
}
