import { POLICY_EVALUATION } from "src/constants"
import { putRequest } from "src/services"
import { ApplicationPolicyEvaluationFormType } from "./ApplicationPolicyEvaluationForm"

export const applicationPolicyEvaluation = async (data: ApplicationPolicyEvaluationFormType) => {
  const url = POLICY_EVALUATION
  const body = {
    user_id: data?.user?.id, // required
    user_email: data?.user?.email, // required
    access_mode: data?.accessMode, // required
    service: data?.application, // required
    device: data?.device || undefined,
    time_zone: data?.timeZone || undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body.
    location_based_condition:
      data?.location?.filter((val) => val?.value !== "any" && val.value)?.map((val) => val.value) || [], // Optional. Only send this field if user has input on this otherwise don't send in request body.
    start_time: data?.startTime ? data?.startTime?.toISOString() : undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body. Timeformat should be in UTC
    end_time: data?.endTime ? data?.endTime?.toISOString() : undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body. Timeformat should be in UTC
  }
  const resp = await putRequest(url, body)

  return resp
}
