import { html, unsafeCSS } from 'lit';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { property } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import { ENSideMenuItem } from '../side-menu-item/side-menu-item';
import styles from './side-menu.scss';

/**
 * @slot - The component contente
 */
export class ENSideMenu extends ENElement {
  static el = 'en-side-menu';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  private elementMap = register({
    elements: [
      [ENSideMenuItem.el, ENSideMenuItem]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  /**
   * is Active
   * 1) Panel is open when set to true. Close when set to false
   */
  @property({ type: Boolean })
  isCollapsed?: boolean;

  /**
   * When component is updated
   * 1) If the side menu is collapsed, turn the `isCollapsed` property on on the items
   * 2) Otherwise, turn `isCollapsed` off
   * 3) This is needed because of the scoped components
   */
  updated() {
    const sideMenuItems = this.querySelectorAll(this.elementMap.get(ENSideMenuItem.el));
    sideMenuItems.forEach((element: ENSideMenuItem) => {
      if (this.isCollapsed === true) {
        element.isCollapsed = true; /* 1 */
      } else {
        element.isCollapsed = false; /* 2 */
      }
    });
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-side-menu', {
      'en-is-collapsed': this.isCollapsed === true
    });

    return html`
      <div class="${componentClassNames}">
        ${this.slotNotEmpty('header') &&
        html`
          <header class="en-c-side-menu__header">
            <slot name="header"></slot>
          </header>
        `}
        <nav role="navigation" class="en-c-side-menu__nav" aria-label="Side menu nav">
          <ul role="list" class="en-c-side-menu__list">
            <slot></slot>
          </ul>
        </nav>
        ${this.slotNotEmpty('footer') &&
        html`
          <footer class="en-c-side-menu__footer">
            <slot name="footer"></slot>
          </footer>
        `}
      </div>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENSideMenu.el) === undefined) {
  customElements.define(ENSideMenu.el, ENSideMenu);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-side-menu': ENSideMenu;
  }
}