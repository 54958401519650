import { TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { map } from 'lit/directives/map.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENDropdown } from '../dropdown/dropdown';
import { ENListItem } from '../list-item/list-item';
import { ENList } from '../list/list';
import { ENTimezoneDropdown } from '../timezone-dropdown/timezone-dropdown';
import styles from './inline-time-selector.scss';

/**
 * Component: en-inline-time-selector
 * @slot - The components content
 */
export class ENInlineTimeSelector extends ENElement {
  static el = 'en-inline-time-selector';

  private elementMap = register({
    elements: [
      [ENDropdown.el, ENDropdown],
      [ENList.el, ENList],
      [ENListItem.el, ENListItem],
      [ENTimezoneDropdown.el, ENTimezoneDropdown]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private dropdownEl = unsafeStatic(this.elementMap.get(ENDropdown.el));
  private listEl = unsafeStatic(this.elementMap.get(ENList.el));
  private listItemEl = unsafeStatic(this.elementMap.get(ENListItem.el));
  private timezoneDropdownEl = unsafeStatic(this.elementMap.get(ENTimezoneDropdown.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * label
   * Label of time selector
   */
  @property()
  label: string;

  /**
   * direction (Optional)
   * - **column** Show label and date above selector where label will be in left and date will be in right
   * - **row** Show label and date in left of selector where date will be below label.
   */
  @property()
  direction?: 'row' | 'column' = 'column';

  /**
   * variant (Optional)
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary' = 'primary';

  /**
   * datePlaceholder (Optional)
   * Date placeholder. It expects date string.
   */
  @property()
  datePlaceholder?: string;

  /**
   * hours (Optional)
   * Use to set initial hours value. Valid values are 0-23 in string. Any change in hours will also reflect in this value.
   */
  @property({ type: String })
  hours?: string;

  /**
   * minutes (Optional)
   * Use to set initial minutes value. Valid values are 0-59 in string. Any change in minutes will also reflect in this value.
   */
  @property({ type: String })
  minutes?: string;

  /**
   * Mutable timezone property. It always points to selected timezone
   */
  @property({ type: String })
  timezone?: string;

  /**
   * If true, show timezone field else remove it
   */
  @property({ type: Boolean })
  showTimezone?: boolean = false;

  /**
   * If true, then set default timezone to client timezone. Default is true.
   */
  @property({ type: Boolean })
  setDefaultTimezoneToBrowserTimezone?: boolean = true;

  /**
   * seconds (Optional)
   * Use to set initial seconds value. Valid values are 0-59 in string. Any change in minutes will also reflect in this value.
   */
  @property({ type: String })
  seconds?: string;

  /**
   * Default is false. If true hide seconds field.
   */
  @property({ type: Boolean })
  hideSecondsField?: boolean = false;

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * If set then open dropdown in that alignment only. Otherwise auto determine position to open
   */
  @property()
  dropdownAlign?: 'bottom' | 'top';

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = false;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * This property purpose is use within shadow DOM
   */
  @property()
  dropdownPanelContainerShadowDomElement: HTMLElement;

  private _hoursDropdownData: Array<{ label: string; value: string }> = [];
  private _minutesAndSecondsDropdownData: Array<{ label: string; value: string }> = [];

  /**
   * Initialize functions
   */
  constructor() {
    super();
    for (let ind = 0; ind < 24; ++ind) {
      const row = {
        label: ind < 10 ? `0${ind}` : `${ind}`,
        value: ind < 10 ? `0${ind}` : `${ind}`
      };
      this._hoursDropdownData.push(row);
      this._minutesAndSecondsDropdownData.push(row);
    }
    for (let ind = 24; ind < 60; ++ind) {
      this._minutesAndSecondsDropdownData.push({
        label: `${ind}`,
        value: `${ind}`
      });
    }
  }

  private _getFormattedTimeUnit(timeUnit: string) {
    const timeUnitNo = parseInt(`${timeUnit}`, 10);
    if (!isNaN(timeUnitNo)) {
      if (timeUnitNo < 10) {
        return `0${timeUnitNo}`;
      }
      return `${timeUnitNo}`;
    }
    return '00';
  }

  private _handleChange() {
    this.dispatch({
      eventName: 'change',
      detailObj: {
        hours: this._getFormattedTimeUnit(`${this.hours}`),
        minutes: this._getFormattedTimeUnit(`${this.minutes}`),
        seconds: this._getFormattedTimeUnit(`${this.seconds}`),
        timezone: this.timezone
      }
    });
  }

  /**
   * Handles hour selection
   * @param evt Custom Event
   */
  private _handleHoursSelection(evt: CustomEvent) {
    const { selectedValue } = evt.detail;
    if (!selectedValue) {
      this.hours = undefined;
    } else {
      this.hours = selectedValue;
    }
    this._handleChange();
  }

  /**
   * Handles minute selection
   * @param evt Custom Event
   */
  private _handleMinuteSelection(evt: CustomEvent) {
    const { selectedValue } = evt.detail;
    if (!selectedValue) {
      this.minutes = undefined;
    } else {
      this.minutes = selectedValue;
    }
    this._handleChange();
  }

  /**
   * Handles second selection
   * @param evt Custom Event
   */
  private _handleSecondSelection(evt: CustomEvent) {
    const { selectedValue } = evt.detail;
    if (!selectedValue) {
      this.seconds = undefined;
    } else {
      this.seconds = selectedValue;
    }
    this._handleChange();
  }

  private _handleTimezoneSelection(evt: CustomEvent) {
    const { timezone } = evt.detail;
    if (!timezone) {
      this.timezone = undefined;
    } else {
      this.timezone = timezone;
    }
    this._handleChange();
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-inline-time-selector', {
      'en-c-inline-time-selector__direction--row': this.direction === 'row'
    });

    const parsedHours = parseInt(this.hours, 10) || 0;
    const parsedMinutes = parseInt(this.minutes, 10) || 0;
    const parsedSeconds = parseInt(this.seconds, 10) || 0;

    return html`
      <div class="${componentClassNames}">
        <div class="time-selector__text">
          <div class="time-selector__text--label">${this.label}</div>
          ${this.datePlaceholder ? html`<div class="time-selector__text--date">${this.datePlaceholder}</div>` : html``}
        </div>
        <div class="time-selector__dropdown">
          <div class="time-selector__dropdown--hour">
            <${this.dropdownEl} .enableDynamicPositioning=${this.enableDynamicPositioning} dropdownPanelContainerSelector="${this.dropdownPanelContainerSelector}" .dropdownPanelContainerShadowDomElement=${this.dropdownPanelContainerShadowDomElement} variant="${this.variant}" align=${ifDefined(this.dropdownAlign)} label="HH" value=${this.hours ?? ''} @selectValue=${
              this._handleHoursSelection
            } ?isError=${parsedHours > 23 || parsedHours < 0}>
              <${this.listEl}>${map(
                this._hoursDropdownData,
                (row) => html`<${this.listItemEl} value="${row.value}">${row.label}</${this.listItemEl}>`
              )}
              </${this.listEl}>
            </${this.dropdownEl}>
          </div>
          <div class="seperator">:</div>
          <div class="time-selector__dropdown--minute">
            <${this.dropdownEl} .enableDynamicPositioning=${this.enableDynamicPositioning} dropdownPanelContainerSelector="${this.dropdownPanelContainerSelector}" .dropdownPanelContainerShadowDomElement=${this.dropdownPanelContainerShadowDomElement} variant="${this.variant}" label="mm" align=${ifDefined(this.dropdownAlign)} value=${this.minutes ?? ''} @selectValue=${
              this._handleMinuteSelection
            } ?isError=${parsedMinutes > 59 || parsedMinutes < 0}>
                <${this.listEl}>${map(
                  this._minutesAndSecondsDropdownData,
                  (row) => html`<${this.listItemEl} value="${row.value}">${row.label}</${this.listItemEl}>`
                )}</${this.listEl}>
            </${this.dropdownEl}>
          </div>
          ${!this.hideSecondsField ? html`<div class="seperator">:</div>` : html``}
          <div class="${classMap({ 'en-hide': this.hideSecondsField, 'time-selector__dropdown--second': true })}">
            <${this.dropdownEl} .enableDynamicPositioning=${this.enableDynamicPositioning} dropdownPanelContainerSelector="${this.dropdownPanelContainerSelector}" .dropdownPanelContainerShadowDomElement=${this.dropdownPanelContainerShadowDomElement} variant="${this.variant}" label="ss" align=${ifDefined(this.dropdownAlign)} value=${this.seconds ?? ''} @selectValue=${
              this._handleSecondSelection
            } ?isError=${parsedSeconds > 59 || parsedSeconds < 0}>
              <${this.listEl}>${map(
                this._minutesAndSecondsDropdownData,
                (row) => html`<${this.listItemEl} value="${row.value}">${row.label}</${this.listItemEl}>`
              )}</${this.listEl}>
            </${this.dropdownEl}>
          </div>
        </div>
        ${
          this.showTimezone
            ? html`<div class="${classMap({ timezone__dropdown: true, 'en-is-empty': !this.timezone })}">
          <${this.timezoneDropdownEl} .setDefaultTimezoneToBrowserTimezone=${this.setDefaultTimezoneToBrowserTimezone} .enableDynamicPositioning=${this.enableDynamicPositioning} dropdownPanelContainerSelector="${this.dropdownPanelContainerSelector}" .dropdownPanelContainerShadowDomElement=${this.dropdownPanelContainerShadowDomElement} variant="${this.variant}" align=${ifDefined(this.dropdownAlign)} timezone=${this.timezone ?? ''} @timezoneChange=${
            this._handleTimezoneSelection
          }>
            </${this.timezoneDropdownEl}>
        </div>`
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENInlineTimeSelector.el) === undefined) {
  customElements.define(ENInlineTimeSelector.el, ENInlineTimeSelector);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-inline-time-selector': ENInlineTimeSelector;
  }
}
