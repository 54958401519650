import { html, LitElement, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

export interface ENElementProps {
  styleModifier?: string;
}

export interface DetailObj {
  [key: string]: unknown;
}

export interface ENDispatchProps {
  e?: Event;
  eventName: string;
  detailObj?: DetailObj;
  optionsObj?: { [key: string]: unknown };
}

export interface ENEvent extends Event {
  detail: {
    originalEvent: Event;
    detailObj: DetailObj;
  };
}

type eventHandlerFn = (a0?: any) => any;

/**
 * A base element.
 */
export class ENElement extends LitElement {
  /**
   * Append to the class name. Used for passing in utility classes
   */
  @property()
  styleModifier?: string;

  /**
   * Abstraction of `classMap` that automatically includes any style modifier
   * as well as any set variants.
   *
   * It is expected that `variant` would be overridden in a subclass with more
   * specific types, `@property() variant?: 'foo' | 'bar'`
   *
   * @param baseClassName
   */
  componentClassNames(baseClassName: string, additionalClassNames = {}) {
    return classMap({
      [baseClassName]: !!baseClassName,
      [this.styleModifier]: !!this.styleModifier,
      ...additionalClassNames
    });
  }

  /**
   * Check if a slot is empty
   *
   * @param slotName
   */
  slotEmpty(slotName?: string) {
    return !this.querySelector(`[slot${slotName ? `="${slotName}"` : ''}]`);
  }

  /**
   * Check if a slot is not empty
   *
   * @param slotName
   */
  slotNotEmpty(slotName?: string) {
    if (!this.slotEmpty(slotName) !== false) {
      return !this.slotEmpty(slotName);
    } else {
      return;
    }
  }

  /**
   * Dispatch a custom event.
   */
  dispatch({ e, eventName, detailObj = {}, optionsObj = {} }: ENDispatchProps): CustomEvent {
    const options = {
      bubbles: true,
      composed: true,
      ...optionsObj,
      detail: { ...(e && { originalEvent: e }), ...detailObj }
    };
    const event = new CustomEvent(eventName, options);
    this.dispatchEvent(event);
    return event;
  }

  /**
   * Example render, should not be used
   */
  render() {
    return html` <slot></slot> `;
  }

  constructor() {
    super();

  }

  @property({ type: String, reflect: true, attribute: 'persistenceId' })
  persistenceId?: string;

  @property({ type: String, reflect: true, attribute: 'persistProps' })
  persistProps?: string;

  @state()
  uiStateEventMap?: { [key: string]: string | ((a0?: any) => any) };

  private readonly modifiedProps: PropertyValues = new Map<PropertyKey, unknown>();

  update(changedProperties: PropertyValues) {
    super.update(changedProperties);
    const pid = this.getAttribute('persistenceId');
    if (!pid) {
      return;
    }
    let pprops: string | string[] = this.getAttribute('persistProps');
    pprops = Array.isArray(pprops) ? pprops : (pprops?.split(',') || []);
    (pprops as string[]).forEach((prop: string) => {
      const newValue = ((this as any)[prop] ?? this.getAttribute(prop));
      const oldValue = changedProperties.get(prop) || ''; // Force '' if undefined so logic works
      if ((newValue !== oldValue) && (typeof newValue === 'string') || (typeof newValue === 'number') || (typeof newValue === 'boolean')) {
        this.modifiedProps.set(prop, newValue);
      }

    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.applyPersistedState(); // Load persisted state on connect (this works in most cases)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    const pid = this.getAttribute('persistenceId');
    if (!pid) {
      return;
    }
    this.persistUIState();
  }

  applyPersistedState() {
    const pid = this.getAttribute('persistenceId');
    if (!pid) {
      return;
    }
    const statePersistService = (window as any).UI_PERSIST;

    if (pid && (window as any).UI_PERSIST) {
      statePersistService.getState(pid).then((state: any) => {
        if (!state) {
          return;
        }
        const map = new Map<PropertyKey, unknown>(Object.entries(state));
        if (Object.keys(map).length === 0 && map.constructor === Object) {
          return;
        }
        let pprops: string | string[] = this.getAttribute('persistProps');
        pprops = Array.isArray(pprops) ? pprops : pprops.split(',');
        map.forEach((entry: any[]) => {
          const [propName, value] = entry;
          if (pprops.indexOf(propName as string) >= 0) {
            this.setAttribute(propName as string, value ?? undefined);
            (this as any)[propName] = value ?? undefined; // Hack to force the property to be set correctly

            if (this.uiStateEventMap && this.uiStateEventMap[propName]) {
              if (typeof this.uiStateEventMap[propName] === 'string') {
                const fnName = this.uiStateEventMap[propName] as string;
                if ((this as any)[fnName]) {
                  (this as any)[fnName]({ target: this })
                }
              } else {
                const fn = this.uiStateEventMap[propName] as eventHandlerFn;
                setTimeout(() => fn({ target: this }), 10);
              }
            }
          }
        });
      });
    }
  }

  public persistUIState() {
    const pid = this.getAttribute('persistenceId');
    if (!pid) {
      return;
    }

    const statePersistService = (window as any).UI_PERSIST;

    if (pid && statePersistService) {
      // Convert the Map to an array of key-value pairs
      const propsToPersist = this.modifiedProps;
      const mapArray = Array.from(propsToPersist);
      if (mapArray.length === 0) {
        return;
      }
      statePersistService.saveState(pid, mapArray);
      this.modifiedProps.clear();// Once its saved, delete it from the modifiedProps
    }
  }


}

ENElement.addInitializer((instance: ENElement) => {
  // This is run during construction of the element

  // This ugly hack is needed because in React, en-web-components are not fully initialized
  // with the properties until some time after the constructor is called.
  // This timeout allows for the peristenceId to be propagated to the actual element before we 
  // try to load the initial state from the persistence service.
  setTimeout(() => {
    //console.log('ENElement initialized', instance);
    instance.applyPersistedState();
  }, 150);
})