import { PropertyValues, TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENButton } from '../button/button';
import { ENDropdownPanel } from '../dropdown-panel/dropdown-panel';
import { ENElement } from '../ENElement';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconClock } from '../icon/icons/clock';
import { ENIconClose } from '../icon/icons/close';
import { ENInlineTimeSelector } from '../inline-time-selector/inline-time-selector';
import { ENTextField } from '../text-field/text-field';
import styles from './time-picker.scss';

/**
 * Component: en-time-picker
 * @slot - The components content
 */
export class ENTimePicker extends ENElement {
  static el = 'en-time-picker';

  private elementMap = register({
    elements: [
      [ENTextField.el, ENTextField],
      [ENButton.el, ENButton],
      [ENIconClock.el, ENIconClock],
      [ENIconClose.el, ENIconClose],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENFieldNote.el, ENFieldNote],
      [ENDropdownPanel.el, ENDropdownPanel],
      [ENInlineTimeSelector.el, ENInlineTimeSelector]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private iconClockEl = unsafeStatic(this.elementMap.get(ENIconClock.el));
  private iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private dropdownPanelEl = unsafeStatic(this.elementMap.get(ENDropdownPanel.el));
  private inlineTimeSelectorEl = unsafeStatic(this.elementMap.get(ENInlineTimeSelector.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Variant
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary' = 'primary';

  /**
   * Hide the label?
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * The select field's required attribute
   */
  @property({ type: Boolean })
  isRequired?: boolean = false;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * The select field's disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * If true, then add cross icon on dropdown input which enables clearing selection. Default value is true.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * The dropdown error note
   */
  @property()
  errorNote?: string;

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * The dropdown field note
   */
  @property()
  fieldNote?: string;

  /**
   * The select field's name attribute
   */
  @property()
  name?: string;

  /**
   * The select field's value attribute
   */
  @property()
  value?: string;

  /**
   * Textfield Active state
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * isActiveDropdown
   * 1. Dropdown is open when set to true. Close when set to false
   */
  @property({ type: Boolean })
  isActiveDropdown?: boolean;

  /**
   * The unique id of the select field
   */
  @property()
  fieldId?: string;

  /**
   * Aria describedby
   * 1. Used to connect the field note in select field to the select menu for accessibility
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * The select field's title
   */
  @property()
  title: string;

  /**
   * The select field's label
   */
  @property()
  label = 'Label';

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * Default is bottom. Releavance of setting it is only when `enableDynamicPositioning` is set to false.
   */
  @property()
  align?: 'bottom' | 'top' = 'bottom';

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = true;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * Set timezone of time picker
   */
  @property()
  timezone?: string = '';

  /**
   * If true, show timezone field else remove it
   */
  @property({ type: Boolean })
  showTimezone?: boolean = false;

  /**
   * Save the updated value. NOTE: We are not making change directly in this.value so as to keep that state immutable
   */
  @state()
  private _value: string = '';

  /**
   * State store the reference of dropdown panel when dropdown is opened. It is set to null when dropdown is closed.
   */
  @state()
  private _timePickerDropdownPanelEl: ENDropdownPanel = null;

  /**
   * Initialize functions
   */
  constructor() {
    super();
  }

  /**
   * Connected Callback lifecycle
   * 1. Close dropdown panel when you click outside of the element
   * 2. Autogenerate the fieldID
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    document.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    /* 2 */
    this.fieldId = this.fieldId || nanoid(); /* 2 */
  }

  /**
   * Updated Lifecycle method
   * 1. Iterate all changed properties and states
   * 2. If changed property is value then update _value state and text-field active state
   * @param _changedProperties
   */
  protected updated(_changedProperties: PropertyValues): void {
    /* 1 */
    _changedProperties.forEach((oldValue, propName) => {
      /* 2 */
      if (propName === 'value' && this.value !== oldValue) {
        const splitValue = this.value === '' ? undefined : this.value.split(':');
        this._value = !splitValue ? '' : `${splitValue[0] || '00'}:${splitValue[1] || '00'}:${splitValue[2] || '00'}`;
        if (!!this._value) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      }
    });
  }

  /**
   * Handle positioning of dropdown based on height of container.
   */
  handleOnActiveDropdown() {
    setTimeout(() => {
      if (this.enableDynamicPositioning) {
        const dropdownPanel = this.shadowRoot.querySelector<HTMLElement>('.en-c-time-picker-dropdown__panel')?.getBoundingClientRect();
        const body = document.querySelector(this.dropdownPanelContainerSelector);
        const bodyPosition = body?.getBoundingClientRect();
        if (!!bodyPosition && bodyPosition.height > dropdownPanel?.height && dropdownPanel?.bottom > bodyPosition.bottom) {
          /* Position dropdown panel based on viewport space */
          this.align = 'top'; /* 1 */
        }
      }
    }, 0);
  }

  /**
   * Handle inline time selector change. Dispatch 'timeChange' event on time change
   * @param evt
   */
  handleTimeSelectorChange = (evt: CustomEvent) => {
    const { hours, minutes, seconds, timezone } = evt.detail;
    console.log('timezone', timezone);
    this.timezone = timezone ?? '';
    this._value = `${hours || '00'}:${minutes || '00'}:${seconds || '00'}`;
    if (this._value) {
      this.isActive = true;
    }
    this.dispatch({ eventName: 'timeChange', detailObj: { value: this._value } });
  };

  /**
   * Toggle the time picker dropdown
   * 1. If dropdown is opened, then get dropdown panel element and assign to _timePickerDropdownPanelEl state. Call handleOnActiveDropdown and dispatch open even.
   * 2. If dropdown is closed, then set _timePickerDropdownPanelEl state to null, focus back on input element and dispatch close event
   */
  toggleActive() {
    if (this.isDisabled) {
      return false;
    }
    this.isActiveDropdown = !this.isActiveDropdown; /* 1 */
    if (this.isActiveDropdown === true) {
      /* 1 */
      setTimeout(() => {
        this._timePickerDropdownPanelEl = this.shadowRoot.querySelector('.en-c-time-picker-dropdown__panel');
      }, 0);

      this.handleOnActiveDropdown();
      this.dispatch({ eventName: 'timeOpen', detailObj: { active: true, value: this._value } });
    } else {
      /* 2 */
      this._timePickerDropdownPanelEl = null;
      this.shadowRoot?.querySelector<HTMLInputElement>('.en-c-time-picker__input')?.focus();
      this.dispatch({ eventName: 'timeClose', detailObj: { active: false, value: this._value } });
    }
  }

  /**
   * Handle enable clear time selection
   * 1. stopPropagation is added so as to not trigger click on input element.
   * 2. If dropdown is open, then close it
   * @param evt Mouse Event
   */
  handleEnableClearSelection = (evt: MouseEvent) => {
    if (this.isDisabled) {
      return false;
    }
    /* 1 */
    evt.preventDefault();
    evt.stopPropagation();
    const temp = this._value;
    this._value = '';
    this.isActive = false;
    if (this.isActiveDropdown) {
      /* 2 */
      this.toggleActive();
    }
    this.dispatch({ eventName: 'timeClearSelection', detailObj: { clearedValue: temp, clickEvt: evt } });
  };

  /**
   * Handle click outside the component
   * 1. If the nav is already closed then we don't care about outside clicks and we
   * can bail early
   * 2. By the time a user clicks on the page the shadowRoot will almost certainly be
   * defined, but TypeScript isn't that trusting and sees this.shadowRoot as possibly
   * undefined. To work around that we'll check that we have a shadowRoot (and a
   * rendered .host) element here to appease the TypeScript compiler. This should never
   * actually be shown or run for a human end user.
   * 3. Check to see if we clicked inside the active panel
   * 4. If the panel is active and we've clicked outside of the panel then it should
   * be closed.
   */
  handleOnClickOutside = (event: MouseEvent) => {
    /* 1 */
    if (!this.isActiveDropdown) {
      return;
    }
    /* 2 */
    if (!this.shadowRoot?.host) {
      throw Error('Could not determine panel context during click handler');
    }
    /* 3 */
    const didClickInside = event.composedPath().includes(this.shadowRoot.host);
    /* 4 */
    if (this.isActiveDropdown && !didClickInside) {
      this.toggleActive();
    }
  };

  /**
   * Handle on keydown dropdown panel
   * 1. If the panel is open and escape is keyed, close the menu and return focus to the trigger button
   */
  handleOnKeydownDropdownPanel(e: KeyboardEvent) {
    if (this.isActiveDropdown === true && e.code === 'Escape') {
      this.toggleActive();
    }
  }

  /**
   * Handle on select input keydown
   * 1. If key selected is enter or spacebar, toggle the menu open/close
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleActive();
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-time-picker', {
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive === true,
      'en-is-active-dropdown': this.isActiveDropdown === true,
      'en-has-hidden-label': this.hideLabel === true,
      'en-c-dropdown--align-bottom': this.align === 'bottom',
      'en-c-dropdown--align-top': this.align === 'top'
    });

    const splitValue = this._value === '' ? [] : this._value.split(':');

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-time-picker__container">
          <${this.textFieldEl}
            class="en-c-time-picker__input"
            paddingEndOffset=${this.enableClearSelection && !!this._value ? 24 : 0}
            type="text"
            variant="${this.variant}"
            label="${this.label}"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            value="${ifDefined(this._value)}"
            ?hideLabel="${this.hideLabel}"
            .isReadonly=${true}
            ?isRequired="${this.isRequired}"
            ?isOptional="${this.isOptional}"
            ?isDisabled="${this.isDisabled}"
            ?isError="${this.isError}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            @click=${this.toggleActive}
            @keydown=${this.handleOnKeydown}
            ?isActive="${this.isActive}"
            enableSlotAfterClick=${true}
            .forceWidthBeforeSlot=${24}
          >
            <${this.iconClockEl} slot="before" class="en-c-time-picker__icon-clock"></${this.iconClockEl}>
            ${
              this.enableClearSelection
                ? html`<div slot="after" style="display:flex;align-items:center">
              ${
                this._value
                  ? html`<${this.buttonEl}  ?hideText=${true} @click=${this.handleEnableClearSelection} variant="quaternary">
                <${this.iconCloseEl} slot="after" size="md"></${this.iconCloseEl}>
              </${this.buttonEl}>`
                  : html``
              }
              <${this.iconChevronDownEl} slot="after" class="en-c-time-picker__icon-arrow"></${this.iconChevronDownEl}>
            </div>`
                : html`<${this.iconChevronDownEl} slot="after" class="en-c-time-picker__icon-arrow"></${this.iconChevronDownEl}>`
            }

          </${this.textFieldEl}>
          ${
            this.isActiveDropdown
              ? html`
                <${this.dropdownPanelEl} .hasScroll=${false} @keydown=${this.handleOnKeydownDropdownPanel} class="en-c-time-picker-dropdown__panel" .hasHeader=${false}>
                  <${this.inlineTimeSelectorEl} @change=${this.handleTimeSelectorChange} .showTimezone=${this.showTimezone} timezone="${this.timezone}" .hours=${splitValue[0]} .minutes=${splitValue[1]} .seconds=${splitValue[2]}  .enableDynamicPositioning=${true} .dropdownPanelContainerShadowDomElement=${this._timePickerDropdownPanelEl} class="en-c-time-selector"></${this.inlineTimeSelectorEl}>
                </${this.dropdownPanelEl}>
              `
              : html``
          }
          </div>
        ${
          this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
        ${
          (this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTimePicker.el) === undefined) {
  customElements.define(ENTimePicker.el, ENTimePicker);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-time-picker': ENTimePicker;
  }
}
