import { addYears, endOfDay, endOfYear, format, isAfter, isBefore, isValid, startOfDay, startOfYear, subYears, toDate } from 'date-fns';
import { TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENDoubleRangeCalendar } from '../double-range-calendar/double-range-calendar';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconCalendar } from '../icon/icons/calendar';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENRangeCalendar } from '../range-calendar/range-calendar';
import { ENTextField } from '../text-field/text-field';
import styles from './datetimepicker-field.scss';

/**
 * Component: en-datetimepicker-field
 * @slot - The components content
 */
export class ENDatetimepickerField extends ENElement {
  static el = 'en-datetimepicker-field';

  private elementMap = register({
    elements: [
      [ENTextField.el, ENTextField],
      [ENFieldNote.el, ENFieldNote],
      [ENDoubleRangeCalendar.el, ENDoubleRangeCalendar],
      [ENRangeCalendar.el, ENRangeCalendar],
      [ENIconCalendar.el, ENIconCalendar],
      [ENIconChevronDown.el, ENIconChevronDown]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private rangeCalendarEl = unsafeStatic(this.elementMap.get(ENRangeCalendar.el));
  private doubleRangeCalendarEl = unsafeStatic(this.elementMap.get(ENDoubleRangeCalendar.el));
  private iconCalendarEl = unsafeStatic(this.elementMap.get(ENIconCalendar.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * The unique id of the field
   */
  @property()
  fieldId?: string;

  /**
   * The field's label
   */
  @property()
  label: string = 'Date and Time';

  /**
   * Hide label?
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * The field's name attribute
   */
  @property()
  name?: string;

  /**
   * The field's value attribute. Recommended is not to set it explicitly. Instead set initial properties if want to initialize field.
   */
  @property()
  value?: string;

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * The field note displayed beneath the field
   */
  @property()
  fieldNote?: string;

  /**
   * Error note displayed beneath the field when in an error state
   */
  @property()
  errorNote?: string;

  /**
   * Aria describedby
   * - Used to connect the field note in the field to the input for accessibility
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * Use to set calendar width. Default is 25rem
   */
  @property()
  calendarWidth?: string;

  /**
   * Use to set calendar max height. Default is 450px
   */
  @property()
  calendarMaxHeight?: string = '450px';

  /**
   * The field's required attribute
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * The field's disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Error state indicating an issue with the field
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * If true then allows to select range of dates. Default is false.
   */
  @property({ type: Boolean })
  enableRangeSelection?: boolean = false;

  /**
   * If true show Last Week Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastWeekChip?: boolean = false;

  /**
   * If true show Last Month Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastMonthChip?: boolean = false;

  /**
   * If true show Last Quarter Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastQuarterChip?: boolean = false;

  /**
   * If true, then append 'Z' at the end of time. Default is false.
   */
  @property({ type: Boolean })
  isUtc?: boolean = false;

  /**
   * If true, user can select month and year. Default is false. For range selection, it will always be false even if you set it true.
   */
  @property({ type: Boolean })
  enableMonthAndYearSelection: boolean = false;

  /**
   * If set to true, then apply overflow ellipses in case text exceed input field. Default is false.
   */
  @property({ type: Boolean })
  applyOverflowEllipses: boolean = false;

  /**
   * If true, seconds will not be shown in text field. Default is false. It may be required to set for fields which have small space.
   */
  @property({ type: Boolean })
  hideSeconds: boolean = false;

  /**
   * Indicates whether a date has been selected
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * If set to true, then show timezone field and switch if enabled. Default is false.
   */
  @property({ type: Boolean })
  showTimezone?: boolean = false;

  /**
   * If set to true, then show timezone switch that can hide and show timezone field. Default is true.
   */
  @property({ type: Boolean })
  showTimezoneSwitch?: boolean = true;

  /**
   * If true, then set default timezone to client timezone. Default is true.
   */
  @property({ type: Boolean })
  setDefaultTimezoneToBrowserTimezone?: boolean = true;

  /*
   * Set this property if you want to set timezone switch on at initial load. Default is false.
   */
  @property({ type: Boolean })
  setTimezoneSwitchOnAtInitialLoad?: boolean = false;

  /**
   * timeSelectorDirection
   * - **column** Show label and date above selector where label will be in left and date will be in right
   * - **row** Show label and date in left of selector where date will be below label.
   */
  @property()
  timeSelectorDirection?: 'row' | 'column' = 'column';

  /**
   * Tracks the display state of the datetimepicker
   */
  @state()
  isActiveCalendar: boolean;

  /**
   * Expects valid date string which JS Date functions should be able to read. In range selection it is start date otherwise single date.
   */
  @state()
  selectedDate?: any;

  /**
   * Expects valid date string which JS Date functions should be able to read. Relevant only in case of range selection. It is range end date.
   */
  @state()
  selectedEndDate?: string;

  /**
   * Expects time HH:mm:ss format. In range selection it is start date time otherwise it is only date time.
   */
  @state()
  selectedDateTime?: string;

  /**
   * Expects time HH:mm:ss format. This property is relevant only in range selection. In range selection it is end date time.
   */
  @state()
  selectedEndDateTime?: string;

  /**
   * If true than reset data will not be determined by initial start and end date
   */
  @property({ type: Boolean })
  determineResetDateFromInitialDate?: boolean = true;

  /**
   * Initialize it in case reset start date is different from initialSelectedDate. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedDate?: string = '';

  /**
   * To initialize intial end selected date. Relevant only in case of range selection. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedEndDate?: string = '';

  /**
   * Reset Start Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetStartSelectedTime: string = '';

  /**
   * Reset End Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetEndSelectedTime: string = '';

  /**
   * Reset Timezone.
   */
  @property()
  resetTimezone: string = '';

  /**
   * The field's raw date value
   */
  @state()
  rawDateValue?: any;

  /**
   * The field's raw end date value. This property is relevant only when range selection is enabled.
   */
  @state()
  rawEndDateValue?: any;

  /**
   * ISO Format for date
   */
  @property()
  isoFormat?: string = 'yyyy-MM-dd';

  /**
   * Specify date format for UI display
   */
  @property()
  dateFormat?: 'yyyy-MM-dd' | 'MMM dd, yyyy' | 'MM/dd/yyyy' | 'dd/MM/yyyy' | 'yyyy/MM/dd' | 'MMM dd yyyy' | 'dd MMM yyyy' = 'yyyy-MM-dd';

  /**
   * Minimum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMinDate?: any;

  /**
   * Maximum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMaxDate?: any;

  /**
   * Amount of years to go before and after the current date
   */
  @property({ type: Number })
  multiYear?: number = 3;

  /**
   * Previous month button text
   */
  @property()
  previousButtonText?: string = 'Previous Month';

  /**
   * Next month button text
   */
  @property()
  nextButtonText?: string = 'Next Month';

  /**
   * Show the day of the week as a short hand, e.g. "M" for Monday
   */
  @property({ type: Boolean })
  isDayShortHand?: boolean;

  /**
   * Start the day of the week on Monday
   */
  @property({ type: Boolean })
  startOnMonday?: boolean;

  /**
   * If set to true show double view calendar. Default is false.
   */
  @property({ type: Boolean })
  setDoubleView?: boolean = false;

  /**
   * If set to true disable click outside. Default is false.
   */
  @property({ type: Boolean })
  disableClickOutside?: boolean = false;

  /**
   * Variant
   * - **primary** renders the datetimepicker-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the datetimepicker-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   */
  @property()
  variant?: 'primary' | 'secondary';

  /**
   * If true show calendar footer. Calendar footer has done and reset button controls. Default is true.
   */
  @property({ type: Boolean })
  showCalendarFooter?: boolean = true;

  /**
   * If true, disable reset button. Default is false. Use this property with care as once setting it you will be responsible to unset reset button.
   */
  @property({ type: Boolean })
  forceDisableResetButton: boolean = false;

  /**
   * If true, disable done button. Default is false.
   */
  @property({ type: Boolean })
  disableDoneButton: boolean = false;

  /**
   * If true, disable start/end date and time validation. Default is false. If it will be true, then in close, done and change events error and errorNote returned will be NULL.
   */
  @property({ type: Boolean })
  disableDateAndTimeValidation: boolean = false;

  /**
   * If true hide Done button from footer. Default is false.
   */
  @property({ type: Boolean })
  hideDoneFromFooter?: boolean = false;

  /**
   * Use to set start or end date less than min date error message. It has a default value too.
   */
  @property()
  dateLessThanMinDateErrorMsg = 'Selected date can not be less than minimum date.';

  /**
   * Use to set start or end date more than max date error message. It has a default value too.
   */
  @property()
  dateMoreThanMaxDateErrorMsg = 'Selected date can not be more than maximum date.';

  /**
   * Use to set start date more than end date error message. It has a default value too.
   */
  @property()
  startDateMoreThanEndDateErrorMsg = 'Start date and time must be less than or equal to end date or time.';

  /**
   * Default is false. If true hide seconds field.
   */
  @property({ type: Boolean })
  hideSecondsField?: boolean = false;

  /**
   * To initialize intial start selected date
   */
  @property()
  initialSelectedDate?: string;

  /**
   * To initialize intial end selected date. Relevant only in case of range selection.
   */
  @property()
  initialSelectedEndDate?: string;

  /**
   * To initialize intial start selected date. In case range selection is disabled, only initialize it. Expects value in HH:mm:ss format.
   */
  @property()
  initialSelectedDateTime?: string;

  /**
   * To initialize intial end selected date. Relevant only if range selection is enabled. Expects value in HH:mm:ss format.
   */
  @property()
  initialSelectedEndDateTime?: string;

  /*
   * Timezone string
   */
  @property()
  timezone?: string = '';

  /**
   * Position datetime picker vertically. Default is bottom. It will be overriden if dynamic positioning is enabled. So disable it in order to set it.
   */
  @property()
  verticalAlign?: 'top' | 'bottom' = 'bottom';

  /**
   * Position date picker horizontally. Default is right.
   */
  @property()
  horizontalAlign?: 'left' | 'right' = 'right';

  /**
   * Dynamically position calendar. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPosition?: boolean = true;

  /**
   * Relevant if dynamic positioning is enabled. Default is 'body'. Set it if you want to change container from where bottom is calculated in order to determine calendar positioning.
   */
  @property()
  dynamicPositionContainerQuerySelector: string = 'body';

  /**
   * Query the popup
   */
  @query('.en-c-datetimepicker-field__popup')
  popup: HTMLElement;

  /**
   * Query the text field
   */
  @query('.en-c-datetimepicker-field__input')
  textField?: ENTextField;

  @query('.en-c-datetimepicker-field__calendar')
  calendar?: ENRangeCalendar | ENDoubleRangeCalendar;

  @state()
  private _toSetStartDate: string;

  @state()
  private _toSetEndDate: string;

  @state()
  private _toSetStartTime: string;

  @state()
  private _toSetEndTime: string;

  /**
   * Initializations
   * 1. Binds the 'handleOnClickOutside' method to the component instance
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this); /* 1 */
  }

  /**
   * Connected callback
   * 1. Attaches a mousedown event listener for handling clicks outside the component
   * 3. Dynamically sets 'fieldId' and 'ariaDescribedBy' attributes for Accessibility
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    setTimeout(() => {
      this._toSetStartDate = this.initialSelectedDate;
      this._toSetEndDate = this.initialSelectedEndDate;
      this._toSetStartTime = this.initialSelectedDateTime;
      this._toSetEndTime = this.initialSelectedEndDateTime;
    }, 0);

    /* 3 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
  }

  /**
   * Disconnected callback
   * 1. Removes event listeners for handling clicks outside and window resize
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    /* 1 */
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * Takes time string in HH:mm:ss format and output it to HH:mm:ss format
   * @param timeStr Time string in HH:mm:ss format
   */
  private _getFormattedTime(timeStr: string) {
    if (!timeStr) return '';
    const timeAr = timeStr.trim().split(':');
    if (timeAr.length === 0) return '';
    if (this.hideSeconds) {
      return `T${timeAr[0] || '00'}:${timeAr[1] || '00'}${this.isUtc ? 'Z' : ''}`;
    }
    return `T${timeAr[0] || '00'}:${timeAr[1] || '00'}${timeAr[2] !== undefined ? `:${timeAr[2] || '00'}` : ''}${this.isUtc ? 'Z' : ''}`;
  }

  /**
   * firstUpdated
   * 1. Check if initial start/end date and time are provided, then set value accordingly.
   */
  firstUpdated() {
    setTimeout(() => {
      if (!this.initialSelectedDate && !this.initialSelectedDateTime) {
        this.value = '';
      } else {
        let formattedStartDate, formattedEndDate;
        const startDate = new Date(this.initialSelectedDate);
        if (isValid(startDate)) {
          startDate.setHours(0, 0, 0, 0);
          formattedStartDate = format(startDate, this.dateFormat);
        }
        if (this.initialSelectedEndDate) {
          const endDate = new Date(this.initialSelectedEndDate);
          if (isValid(endDate)) {
            endDate.setHours(0, 0, 0, 0);
            formattedEndDate = format(endDate, this.dateFormat);
          }
        }
        if (this.enableRangeSelection) {
          if (
            !!formattedStartDate &&
            !!formattedEndDate &&
            (formattedStartDate !== formattedEndDate || this.initialSelectedDateTime !== this.initialSelectedEndDateTime)
          ) {
            this.value = `${formattedStartDate || ''}${this._getFormattedTime(this.initialSelectedDateTime)} - ${formattedEndDate || ''}${this._getFormattedTime(this.initialSelectedEndDateTime)}`;
          } else if (!!startDate || !!this.initialSelectedDateTime) {
            this.value = `${formattedStartDate || ''}${this._getFormattedTime(this.initialSelectedDateTime)}`;
          }
        } else {
          this.value = `${formattedStartDate || ''}${this._getFormattedTime(this.initialSelectedDateTime)}`;
        }
      }
      this._validateDateAndTime();
    }, 1);
  }

  /**
   * Validate start and end date along with start and end time.
   * 1. Determining min and max date based on multi year value.
   * 2. disabledMinDate or disabledMaxDate will override min/max value determined on basis of multi year.
   * 3. Setting start or end date based on start or end time
   * 4. Checking if start or end date between min/max date. If not then setting error state accordingly.
   * 5. Checking if start date is more than end date. This check also includes time.
   * @returns error and errorNote state
   */
  private _validateDateAndTime() {
    if (this.disableDateAndTimeValidation) {
      return { error: null, errorNote: null };
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let disabledMinDate;
    if (this.multiYear) {
      /* 1 */
      disabledMinDate = startOfYear(subYears(currentDate, this.multiYear));
    }
    if (this.disabledMinDate) {
      /* 2 */
      disabledMinDate = startOfDay(toDate(new Date(this.disabledMinDate)));
    }
    let disabledMaxDate;
    if (this.multiYear) {
      /* 1 */
      disabledMaxDate = endOfYear(addYears(currentDate, this.multiYear));
    }
    if (this.disabledMaxDate) {
      /* 2 */
      disabledMaxDate = endOfDay(toDate(new Date(this.disabledMaxDate)));
    }
    let startDate = null;
    if (this._toSetStartDate) {
      /* 3 */
      if (this._toSetStartTime) {
        startDate = new Date(this._toSetStartDate);
        const splitStartTime = this._toSetStartTime.split(':');
        if (this.isUtc) {
          startDate.setUTCHours(parseInt(splitStartTime[0], 10) || 0, parseInt(splitStartTime[1], 10) || 0, parseInt(splitStartTime[2], 10) || 0, 0);
        } else {
          startDate.setHours(parseInt(splitStartTime[0], 10) || 0, parseInt(splitStartTime[1], 10) || 0, parseInt(splitStartTime[2], 10) || 0, 0);
        }
      } else {
        startDate = new Date(this._toSetStartDate);
        startDate.setHours(0, 0, 0, 0);
      }
    }
    let endDate = null;
    if (this._toSetEndDate) {
      /* 3 */
      if (this._toSetEndDate) {
        endDate = new Date(this._toSetEndDate);
        const splitEndTime = this._toSetEndTime.split(':');
        if (this.isUtc) {
          endDate.setUTCHours(parseInt(splitEndTime[0], 10) || 0, parseInt(splitEndTime[1], 10) || 0, parseInt(splitEndTime[2], 10) || 0, 0);
        } else {
          endDate.setHours(parseInt(splitEndTime[0], 10) || 0, parseInt(splitEndTime[1], 10) || 0, parseInt(splitEndTime[2], 10) || 0, 0);
        }
      } else {
        endDate = new Date(this._toSetEndDate);
        endDate.setHours(0, 0, 0, 0);
      }
    }
    let error = false,
      errorNote = '';
    if (!!startDate) {
      /* 4 */
      if (!!disabledMinDate && isBefore(startDate, startOfDay(disabledMinDate))) {
        errorNote = this.dateLessThanMinDateErrorMsg;
        error = true;
      } else if (!!disabledMaxDate && isAfter(startDate, endOfDay(disabledMaxDate))) {
        errorNote = this.dateMoreThanMaxDateErrorMsg;
        error = true;
      }
    }
    if (!error && this.enableRangeSelection && !!endDate) {
      /* 4 */
      if (!!disabledMinDate && isBefore(endDate, startOfDay(disabledMinDate))) {
        errorNote = this.dateLessThanMinDateErrorMsg;
        error = true;
      } else if (!!disabledMaxDate && isAfter(endDate, endOfDay(disabledMaxDate))) {
        errorNote = this.dateMoreThanMaxDateErrorMsg;
        error = true;
      }
    }
    if (!error && this.enableRangeSelection && !!startDate && !!endDate && isAfter(startDate, endDate)) {
      /* 5 */
      error = true;
      errorNote = this.startDateMoreThanEndDateErrorMsg;
    }

    this.isError = error;
    this.errorNote = errorNote;
    return { error, errorNote };
  }

  /**
   * Toggle active calendar
   * 1. Checks if the component is disabled; if not, toggles the calendar's display
   * 2. Toggles the calendar display
   * 3. Sets dynamic position for the calendar
   * 5. Dispatches custom events 'open' or 'close' based on the calendar state
   */
  toggleActiveCalendar(evtType = '') {
    /* 1 */
    if (!this.isDisabled) {
      this.isActiveCalendar = !this.isActiveCalendar; /* 2 */
      this.setDynamicPosition(); /* 3 */
    }

    /* 5 */
    if (this.isActiveCalendar) {
      this.dispatch({
        eventName: 'datetimepicker-open',
        detailObj: {
          activeCalendar: this.isActiveCalendar
        }
      });
    } else {
      if (!this.value) {
        this.isActive = false;
      }
      this.dispatch({
        eventName: 'datetimepicker-close',
        detailObj: {
          activeCalendar: this.isActiveCalendar,
          value: this.value,
          startDate: this.selectedDate,
          endDate: this.selectedEndDate,
          startTime: this.selectedDateTime,
          endTime: this.selectedEndDateTime,
          error: this.isError,
          errorNote: this.errorNote
        }
      });
      if (evtType === 'done') {
        this.dispatch({
          eventName: 'done',
          detailObj: {
            activeCalendar: this.isActiveCalendar,
            value: this.value,
            startDate: this.selectedDate,
            endDate: this.selectedEndDate,
            startTime: this.selectedDateTime,
            endTime: this.selectedEndDateTime,
            error: this.isError,
            errorNote: this.errorNote
          }
        });
      }
    }
  }

  /**
   * Handle on input keydown
   * 1. Toggles the calendar open/close if 'Enter' or 'Spacebar' is pressed
   */
  handleOnKeydown(e: KeyboardEvent) {
    /* 1 */
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleActiveCalendar();
    }
  }

  /**
   * Handles the click event outside the component:
   * 1. Check if the calendar is active
   * 2. Determine if the click occurred inside the active field
   * 3. Check if the click occurred outside the active field
   * 4. Close the calendar if the click occurred outside it
   */
  handleOnClickOutside(e: MouseEvent) {
    if (this.disableClickOutside) {
      return false;
    }
    /* 1 */
    if (this.isActiveCalendar) {
      const didClickInside = e.composedPath().includes(this.shadowRoot.host); /* 2 */
      /* 3 */
      if (!didClickInside) {
        /* 4 */
        this.toggleActiveCalendar();
      }
    }
  }

  /**
   * Handle dynamic placement for calendar popup
   * 1. Sets timeout for dynamic positioning of the calendar
   * 2. Adjusts the calendar position based on window and body dimensions
   * 3. Opens the calendar at the top if it's too close to the bottom
   */
  setDynamicPosition() {
    if (!this.enableDynamicPosition) {
      return;
    }
    /* 1 */
    setTimeout(() => {
      if (this.popup) {
        /* 2 */
        this.verticalAlign = 'bottom';
        const body = document.querySelector(this.dynamicPositionContainerQuerySelector)?.getBoundingClientRect();
        const calendarPopup = this.popup.getBoundingClientRect();

        /* 3 */
        if (body && body.height > calendarPopup.height && calendarPopup.bottom > body.height) {
          this.verticalAlign = 'top';
        }
      }
    }, 1);
  }

  /**
   * It updates child component, which after update dispatch change event which is listened by this component.
   * 1. For this case proper start date iso string and end date iso string are required
   * @param startDate Date iso string
   * @param endDate Date iso string
   * @param startTime Date time in HH:mm:ss format
   * @param endTime Date time in HH:mm:ss format
   */
  private _handleChangeInCalendar(startDate: string = '', endDate: string = '', startTime: string = '', endTime: string = '') {
    if (this.calendar) {
      if (!this.setDoubleView) {
        (this.calendar as ENRangeCalendar).handleOnChange('change');
      } else {
        /* 1 */
        (this.calendar as ENDoubleRangeCalendar).handleChange(
          {
            detail: {
              rawStartDate: startDate,
              rawEndDate: endDate
            }
          } as CustomEvent,
          true
        );
        const splittedStartTime = startTime.split(':');
        (this.calendar as ENDoubleRangeCalendar).handleTimeSelectorChange(
          {
            detail: {
              hours: splittedStartTime[0] || '',
              minutes: splittedStartTime[1] || '',
              seconds: splittedStartTime[2] || ''
            }
          } as CustomEvent,
          'start'
        );
        if (this.enableRangeSelection) {
          const splittedEndTime = endTime.split(':');
          (this.calendar as ENDoubleRangeCalendar).handleTimeSelectorChange(
            {
              detail: {
                hours: splittedEndTime[0] || '',
                minutes: splittedEndTime[1] || '',
                seconds: splittedEndTime[2] || ''
              }
            } as CustomEvent,
            'end'
          );
        }
      }
    }
  }

  /**
   * Update start/end date of component. Use this method from outside to update start/end date. Don't call it after dateChanged event as otherwise it will form infinite loop
   * @param startDate ISO string of start date
   * @param endDate ISO string of end date
   * @param startTime Start time in HH:mm:ss format
   * @param endTime End time in HH:mm:ss format
   * @returns update status
   */
  public async updateStartEndDateAndTime(startDate: string, endDate: string = '', startTime = '', endTime = '') {
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);
    const isStartDateValid = isValid(parsedStartDate);
    const isEndDateValid = isValid(parsedEndDate);
    const timeRegex = /^\d{2}\:\d{2}:\d{2}$/;
    const isStartTimeValid = timeRegex.test(startTime);
    const isEndTimeValid = timeRegex.test(endTime);
    if (isStartDateValid) {
      parsedStartDate.setHours(0, 0, 0, 0);
    }
    if (isEndDateValid) {
      parsedEndDate.setHours(0, 0, 0, 0);
    }
    if (isStartDateValid || startDate === '') {
      this._toSetStartDate = isStartDateValid ? parsedStartDate.toISOString() : '';
    }
    if (isEndDateValid || endDate === '') {
      this._toSetEndDate = isEndDateValid ? parsedEndDate.toISOString() : '';
    }
    if (isStartTimeValid || startTime === '') {
      this._toSetStartTime = isStartTimeValid ? startTime : '';
    }
    if (isEndTimeValid || endTime === '') {
      this._toSetEndTime = isEndTimeValid ? endTime : '';
    }
    const shouldUpdate =
      (isStartDateValid || isEndDateValid || startDate === '' || endDate === '') &&
      (isStartTimeValid || isEndTimeValid || startTime === '' || endTime === '');
    if (shouldUpdate && this.calendar) {
      setTimeout(() => {
        this._handleChangeInCalendar(
          isStartDateValid ? parsedStartDate.toISOString() : '',
          isEndDateValid ? parsedEndDate.toISOString() : '',
          isStartTimeValid ? startTime : '',
          isEndTimeValid ? endTime : ''
        );
      }, 1);
    }
    return {
      parsedStartDate,
      parsedEndDate,
      isStartDateValid,
      isEndDateValid,
      isStartTimeValid,
      isEndTimeValid,
      hasUpdated: shouldUpdate && this.calendar
    };
  }

  /**
   * Handle on change of the date
   * 1. Updates 'selectedDate' and 'rawDateValue' based on the event detail
   * 2. Updates 'selectedDateTime' based on event detail
   * 3. If range selection not enabled then update date and time in value
   * 4. If range selection enable then update start/end date and time in value
   * 5. If start/end date both defined and either start/end date are not same or start/end time are not same.
   * 6. If 5 point condition not applies and at least start date or start time is defined
   */
  handleOnChangeDate(e: CustomEvent) {
    /* 1 */
    this.selectedDate = e.detail.startDate;
    this.rawDateValue = e.detail.rawStartDate;
    /* 2 */
    this.selectedDateTime = e.detail.startTime;
    this.selectedEndDateTime = e.detail.endTime;
    this.rawEndDateValue = e.detail.rawEndDate;
    if (this._toSetStartDate !== this.rawDateValue) {
      this._toSetStartDate = this.rawDateValue;
    }
    if (this._toSetEndDate !== this.rawEndDateValue) {
      this._toSetEndDate = this.rawEndDateValue;
    }
    if (this._toSetStartTime !== this.selectedDateTime) {
      this._toSetStartTime = this.selectedDateTime;
    }
    if (this._toSetEndTime !== this.selectedEndDateTime) {
      this._toSetEndTime = this.selectedEndDateTime;
    }
    if (!this.enableRangeSelection) {
      /* 3 */
      this.value = `${this.selectedDate || ''}${this._getFormattedTime(this.selectedDateTime)}`;
    } else {
      /* 4 */
      this.selectedEndDate = e.detail.endDate;
      this.selectedEndDateTime = e.detail.endTime;
      this.rawEndDateValue = e.detail.rawEndDate;
      if (
        !!this.selectedDate &&
        !!this.selectedEndDate &&
        (this.selectedDate !== this.selectedEndDate || this.selectedDateTime !== this.selectedEndDateTime)
      ) {
        /* 5 */
        this.value = `${this.selectedDate || ''}${this._getFormattedTime(this.selectedDateTime)} - ${this.selectedEndDate || ''}${this._getFormattedTime(this.selectedEndDateTime)}`;
      } else if (!!this.selectedDate || !!this.selectedDateTime) {
        /* 6 */
        this.value = `${this.selectedDate || ''}${this._getFormattedTime(this.selectedDateTime)}`;
      } else {
        this.value = '';
      }
    }

    if (!!this.value) {
      this.isActive = true;
    }

    const validationStatus = this._validateDateAndTime();

    this.timezone = e.detail.timezone ?? '';

    this.dispatch({
      eventName: 'dateChanged',
      detailObj: {
        value: this.value,
        startDate: this.selectedDate,
        endDate: this.selectedEndDate,
        startTime: this.selectedDateTime,
        endTime: this.selectedEndDateTime,
        timezone: this.timezone ?? '',
        error: validationStatus.error,
        errorNote: validationStatus.errorNote
      }
    });
  }

  /**
   * Reset initial values in the local component state. If you remove this code then component may work
   * but it may misbehave in further updates. It is important to keep it updated.
   * 1. Trigger update cycle of child component. This will ensure that all states in child are also properly set.
   * We could have directly called handleOnChange but that may left child component states inconsistent.
   * Child component will dispatch change event which will automatically call handleOnChange
   * @param evt
   */
  handleOnReset(evt: CustomEvent) {
    if (this.determineResetDateFromInitialDate) {
      this._toSetStartDate = this.initialSelectedDate;
      this._toSetEndDate = this.initialSelectedEndDate;
      this._toSetStartTime = this.initialSelectedDateTime;
      this._toSetEndTime = this.initialSelectedEndDateTime;
      if (this.calendar) {
        setTimeout(() => {
          /* 1  */
          this._handleChangeInCalendar(
            this.initialSelectedDate,
            this.initialSelectedEndDate,
            this.initialSelectedDateTime,
            this.initialSelectedEndDateTime
          );
        }, 0);
      }
    } else {
      this._toSetStartDate = this.resetSelectedDate;
      this._toSetEndDate = this.resetSelectedEndDate;
      this._toSetStartTime = this.resetStartSelectedTime;
      this._toSetEndTime = this.resetEndSelectedTime;
      if (this.calendar) {
        setTimeout(() => {
          /* 1  */
          this._handleChangeInCalendar(this.resetSelectedDate, this.resetSelectedEndDate, this.resetStartSelectedTime, this.resetEndSelectedTime);
        }, 0);
      }
    }

    // TODO: Remove this code before 0.1.15 release.
    // if (!this.initialSelectedDate && !this.initialSelectedDateTime) {
    //   this.value = '';
    // } else {
    //   if (this.enableRangeSelection) {
    //     const startDate = evt.detail.startDate;
    //     const endDate = evt.detail.endDate;
    //     const startTime = evt.detail.startTime;
    //     const endTime = evt.detail.endTime;
    //     if (!!startDate && !!endDate && (startDate !== endDate || startTime !== endTime)) {
    //       this.value = `${startDate || ''}${this._getFormattedTime(startTime)} - ${endDate || ''}${this._getFormattedTime(endTime)}`;
    //     } else if (!!startDate || !!startTime) {
    //       this.value = `${startDate || ''}${this._getFormattedTime(startTime)}`;
    //     }
    //   } else {
    //     const startDate = evt.detail.startDate;
    //     const startTime = evt.detail.startTime;
    //     this.value = `${startDate || ''}${this._getFormattedTime(startTime)}`;
    //   }
    // }

    this.timezone = evt.detail.timezone ?? '';

    this.dispatch({
      eventName: 'dateReset',
      detailObj: {
        value: this.value,
        initialSelectedDate: this.initialSelectedDate,
        initialSelectedEndDate: this.initialSelectedEndDate,
        initialSelectedDateTime: this.initialSelectedDateTime,
        initialSelectedEndDateTime: this.initialSelectedEndDateTime,
        determineResetDateFromInitialDate: this.determineResetDateFromInitialDate,
        resetSelectedDate: this.resetSelectedDate,
        resetSelectedEndDate: this.resetSelectedEndDate,
        resetStartSelectedTime: this.resetStartSelectedTime,
        resetEndSelectedTime: this.resetEndSelectedTime,
        startDate: evt.detail.startDate,
        startTime: evt.detail.startTime,
        endDate: evt.detail.endDate,
        endTime: evt.detail.endTime,
        timezone: this.timezone
      }
    });
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-datetimepicker-field', {
      'en-has-hidden-label': this.hideLabel,
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active-calendar': this.isActiveCalendar,
      'en-is-active': this.isActive
    });
    if (this.variant === undefined) {
      this.variant = 'primary';
    }

    const calendarEl = this.setDoubleView ? this.doubleRangeCalendarEl : this.rangeCalendarEl;

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-datetimepicker-field__body">
          <${this.textFieldEl}
            .showPointerOnInput=${true}
            .forceWidthBeforeSlot=${24}
            class="en-c-datetimepicker-field__input"
            label="${this.label}"
            id="${this.fieldId}"
            variant="${this.variant}"
            name="${ifDefined(this.name)}"
            value="${ifDefined(this.value)}"
            ?hideLabel="${this.hideLabel}"
            ?isReadonly=${true}
            ?isRequired="${this.isRequired}"
            ?isOptional="${this.isOptional}"
            ?isDisabled="${this.isDisabled}"
            ?isError="${this.isError}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            @click=${this.toggleActiveCalendar}
            @keydown=${this.handleOnKeydown}
            ref="inputField"
            ?isActive="${this.isActive}"
            .applyOverflowEllipses=${this.applyOverflowEllipses}
          >
            <${this.iconCalendarEl} slot="before"></${this.iconCalendarEl}>
            <${this.iconChevronDownEl} slot="after" class="en-c-datetimepicker__icon-arrow"></${this.iconChevronDownEl}>
          </${this.textFieldEl}>
          <div class=${classMap({ 'en-c-datetimepicker-field__popup': true, 'en-c-datetimepicker-field__popup--left': this.horizontalAlign === 'left', 'en-c-datetimepicker-field__popup--top': this.verticalAlign === 'top' })} ?hidden="${!this.isActiveCalendar}" role="dialog">
            <div class="en-c-datetimepicker-field__popup-body">
              <div class="en-c-datetimepicker-field__calendar-container">
                <${calendarEl}
                  class="en-c-datetimepicker-field__calendar"
                  @reset=${this.handleOnReset}
                @change=${this.handleOnChangeDate}
                @done=${(evt: CustomEvent) => {
                  evt.stopPropagation();
                  this.toggleActiveCalendar('done');
                }}
                style="--en-calendar-width:${this.calendarWidth ?? (this.setDoubleView ? '39.5rem' : '25rem')};--en-calendar-container-max-height:${this.calendarMaxHeight}"
                startSelectedDate=${this._toSetStartDate}
                endSelectedDate=${this._toSetEndDate}
                startSelectedTime=${this._toSetStartTime}
                endSelectedTime=${this._toSetEndTime}
                .determineResetDateFromInitialDate=${this.determineResetDateFromInitialDate}
                .resetSelectedDate=${this.resetSelectedDate}
                .resetSelectedEndDate=${this.resetSelectedEndDate}
                .resetStartSelectedTime=${this.resetStartSelectedTime}
                .resetEndSelectedTime=${this.resetEndSelectedTime}
                .forceDisableResetButton=${this.forceDisableResetButton}
                .disableDoneButton=${this.disableDoneButton}
                .enableTimeSelection=${true}
                .enableRangeSelection=${this.enableRangeSelection}
                .showFooter=${this.showCalendarFooter}
                .hideDone=${this.hideDoneFromFooter}
                .disabledMinDate=${this.disabledMinDate}
                .disabledMaxDate=${this.disabledMaxDate}
                .multiYear=${this.multiYear}
                .previousButtonText=${this.previousButtonText}
                .nextButtonText=${this.nextButtonText}
                .dateFormat=${this.dateFormat}
                ?isDayShortHand=${this.isDayShortHand}
                ?startOnMonday=${this.startOnMonday}
                .showLastWeekChip=${this.showLastWeekChip}
                .showLastMonthChip=${this.showLastMonthChip}
                .showLastQuarterChip=${this.showLastQuarterChip}
                .enableMonthAndYearSelection=${this.enableMonthAndYearSelection && !this.enableRangeSelection && !this.setDoubleView}
                timeSelectorVariant=${this.variant}
                timeSelectorDirection=${this.timeSelectorDirection}
                .showTimezone=${this.showTimezone}
                .showTimezoneSwitch=${this.showTimezoneSwitch}
                .setDefaultTimezoneToBrowserTimezone=${this.setDefaultTimezoneToBrowserTimezone}
                .setTimezoneSwitchOnAtInitialLoad=${this.setTimezoneSwitchOnAtInitialLoad}
                timezone=${this.timezone}
                resetTimezone=${this.resetTimezone}
                .hideSecondsField=${this.hideSecondsField}
                ></${calendarEl}>
              </div>
            </div>
          </div>
        </div>
        ${
          this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
          <slot name="field-note">
            <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}>${this.fieldNote}</${this.fieldNoteEl}>
          </slot>
        `
            : html``
        }
        ${
          (this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
          <slot name="error">
            <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}>${this.errorNote}</${this.fieldNoteEl}>
          </slot>
        `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDatetimepickerField.el) === undefined) {
  customElements.define(ENDatetimepickerField.el, ENDatetimepickerField);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-datetimepicker-field': ENDatetimepickerField;
  }
}
