import { TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENIconClose } from '../icon/icons/close';
import { ENTooltip } from '../tooltip/tooltip';
import styles from './chip.scss';

/**
 * Component: en-chip
 * - Chips are compact elements that represent an input, attribute, or action.
 * @slot - The components content
 */
export class ENChip extends ENElement {
  static el = 'en-chip';

  private elementMap = register({
    elements: [
      [ENIconClose.el, ENIconClose],
      [ENTooltip.el, ENTooltip]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private tooltipEl = unsafeStatic(this.elementMap.get(ENTooltip.el));
  private secondaryVariants = ['secondary', 'neutral', 'green', 'red', 'purple', 'amber', 'blue'];
  private colorVariants = ['neutral', 'green', 'red', 'purple', 'amber', 'blue'];

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Variant
   * - **default** A chip with a high contrast background
   * - **secondary** A chip with a medium contrast background
   * - **tertiary** A chip with a low contrast background
   * - **green** A chip with a green background
   * - **red** A chip with a red background
   * - **blue** A chip with a blue background
   * - **amber** A chip with a amber background
   * - **purple** A chip with a purple background
   */
  @property()
  variant?: 'secondary' | 'tertiary' | 'green' | 'red' | 'blue' | 'amber' | 'purple' | 'neutral';

  /**
   * chip data-info attribute
   */
  @property()
  dataInfo?: string = '';

  /**
   * isClickable
   * If true chip will be clickable else it will not be clickable
   */
  @property({ type: Boolean })
  isClickable?: boolean = true;

  /**
   * Type variant
   * - **default** A chip with rounded corners
   * - **squared** A chip with squared corners
   */
  @property()
  type?: 'squared';

  /**
   * Dismissible property
   * - **true** Adds an X to hide the chip on click
   * - **false** Hides the X so the chip can not be hidden
   */
  @property({ type: Boolean })
  isDismissible?: boolean;

  /**
   * Disabled property
   * - **true** Reduce opacity of button to 40% and disable all click events.
   * - **false** Show button in active state
   * Default is false
   */
  @property({ type: Boolean })
  isDisabled?: boolean = false;

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. Pass tooltip text that has to be shown always in it.
   */
  @property()
  tooltipText?: string = '';

  /**
   * If true then set css position to fixed. Default is false.
   */
  setTooltipCSSPositionFixed?: boolean = false;

  /**
   * By default chip show tooltip when text overflow. But if you want to show tooltip always on chip, in that case
   * you can use this property. This property is relevant to set only when tooltipText will be set as these both properties work together
   */
  @property()
  chipText?: string = '';

  /**
   * Is dismissed property
   * - **true** Hides the chip
   * - **false** Shows the chip
   */
  @property({ type: Boolean })
  isDismissed?: boolean;

  /**
   * Set flex on chip. Use only one, either it or `maxWidth`. It will override `maxWidth`
   */
  @property({ type: Number })
  flex?: number;

  /**
   * Set max width on chip. Use only one, either it or `flex`. It will override by `flex`
   */
  @property({ type: String })
  maxWidth?: string;

  /**
   * Set min width on chip.
   */
  @property({ type: String })
  minWidth?: string = '0px';

  /**
   * Enable handling text overflow on chip. Default is false. Relevance of enabling it is only if either flex is set on chip or max width is applied on chip.
   */
  @property({ type: Boolean })
  textOverflow?: boolean = false;

  /**
   * If true show tooltip if text overflow. Default is true. Relevance of this property is only when textOverflow is enabled.
   */
  @property({ type: Boolean })
  showTooltipOnOverflow?: boolean = true;

  /**
   * If true add some delay in text overflow handling. This delay is required to handle specific cases on different browsers. Default is false. Recommended not to set explicitly
   */
  @property({ type: Boolean })
  addSomeDelayInTextOverflowHandling?: boolean = false;

  @property()
  cid?: string = `id_${nanoid()}`;

  @query('.en-c-chip')
  chipEl: HTMLElement;

  @state()
  content: TemplateResult;

  public originalText = '';

  /**
   * First updated lifecycle
   * 1. Set chip style properties
   */
  firstUpdated() {
    setTimeout(() => {
      this.setChipStylePropeties();
    }, 0);
  }

  /**
   * Updated lifecycle
   * 1. Handle flex property update
   * 2. Handle maxWidth property update
   * 3. Handle minWidth property update
   * 4. Handle textOverflow property update
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    let updateChipStyle = false;
    changedProperties.forEach((oldValue, propName) => {
      /* 1 */
      if (propName === 'flex' && this.flex !== oldValue && this.flex) {
        updateChipStyle = true;
      } else if (propName === 'maxWidth' && this.maxWidth !== oldValue && this.maxWidth) {
        /* 2 */
        updateChipStyle = true;
      } else if (propName === 'minWidth' && this.minWidth !== oldValue && this.minWidth) {
        /* 3 */
        updateChipStyle = true;
      } else if (propName === 'textOverflow' && this.textOverflow !== oldValue && this.textOverflow) {
        /* 4 */
        updateChipStyle = true;
      }
    });
    if (updateChipStyle) {
      setTimeout(() => {
        this.setChipStylePropeties();
      }, 0);
    }
  }

  /**
   * Set style variables on chip
   */
  setChipStylePropeties() {
    if (this.flex) {
      this.style.setProperty('--en-chip-dev-flex', '1');
    } else if (this.maxWidth) {
      this.style.setProperty('--en-chip-dev-max-width', this.maxWidth);
    }
    if (this.minWidth) {
      this.style.setProperty('--en-chip-dev-min-width', this.minWidth);
    }
    if (this.textOverflow) {
      this.handleChipTextOverflow();
    }
  }

  /**
   * @param el Element
   * @returns string consist of element font-weight, font-size and font-family
   */
  private getCanvasFont(el: Element) {
    if (el) {
      const { fontWeight, fontSize, fontFamily } = getComputedStyle(el);
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
    return ``;
  }

  /**
   *
   * @param text String whose canvas length has to be measured
   * @param font Font weight, size and family of element which renders that string so that exact length can be measured
   * @returns Canvas render length of string
   */
  private getTextWidth(text: string, font: string) {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  private _handleChipTextOverflow() {
    const chipEl = this.chipEl;
    if (chipEl && !this.content) {
      const textAvailableSpace = this.getTextAvailableSpace();
      const chipText = (this.shadowRoot.querySelector(`slot:not([name='prefix'])`) as HTMLSlotElement)?.assignedNodes()?.[0]?.textContent || '';
      this.originalText = this.chipText || chipText;
      const fontPropertyOnChip = this.getCanvasFont(chipEl);
      const textActualWidth = this.getTextWidth(chipText, fontPropertyOnChip);
      if (textActualWidth > textAvailableSpace) {
        const widthDivideRatio = textAvailableSpace > 0 ? textActualWidth / textAvailableSpace : chipText.length + 1;
        let trimLength = Math.floor(chipText.length / widthDivideRatio);
        const showDots = trimLength > 3;
        trimLength = trimLength > 3 ? trimLength - 3 : trimLength;
        let trimmedChipText = trimLength === 0 ? '..' : chipText.substring(0, trimLength);
        trimmedChipText = showDots ? `${trimmedChipText}...` : trimmedChipText;
        if (this.showTooltipOnOverflow) {
          this.content = html`<${this.tooltipEl} cssPosition="${this.setTooltipCSSPositionFixed ? 'fixed' : 'absolute'}" .isDynamic=${true} .enableTextWrap=${true}
            ><span slot="trigger">${!!this.chipText && trimmedChipText === '..' ? `${this.chipText.substring(0, this.chipText.length / 2)}..` : trimmedChipText}</span>
            ${this.tooltipText || chipText}
          </${this.tooltipEl}>`;
        } else {
          this.content = html`<span>${trimmedChipText}</span>`;
        }
      }
    }
  }

  public handleChipTextOverflow() {
    if (this.addSomeDelayInTextOverflowHandling) {
      setTimeout(() => {
        this._handleChipTextOverflow();
      }, 1);
    } else {
      this._handleChipTextOverflow();
    }
  }

  getTextAvailableSpace() {
    const chipEl = this.chipEl;
    if (chipEl) {
      let noOfGapsInChip = 0;
      const chipWidth = chipEl.clientWidth;
      let prefixWidth = 0;
      if (this.slotNotEmpty('prefix')) {
        ++noOfGapsInChip;
        const prefixEl = this.shadowRoot.querySelector('.en-c-button__icon');
        if (prefixEl) {
          prefixWidth = prefixEl.clientWidth;
        }
      }
      const isColorVariant = this.colorVariants.includes(this.variant);
      let dotWidth = 0;
      if (isColorVariant) {
        ++noOfGapsInChip;
        const dotEl = this.shadowRoot.querySelector('.en-c-chip__colordot');
        if (dotEl) {
          dotWidth = dotEl.clientWidth;
        }
      }
      let dismissibleWidth = 0;
      if (this.isDismissible) {
        ++noOfGapsInChip;
        const chipCloseEl = this.shadowRoot.querySelector('.en-c-chip__close');
        if (chipCloseEl) {
          dismissibleWidth = chipCloseEl.clientWidth;
        }
      }
      const { paddingLeft, paddingRight, gap } = getComputedStyle(chipEl);
      const parsedPaddingLeft = parseFloat(paddingLeft);
      const parsedPaddingRight = parseFloat(paddingRight);
      const parsedGap = parseFloat(gap);
      let textAvailableSpace = chipWidth;
      textAvailableSpace -= parsedPaddingLeft + parsedPaddingRight + noOfGapsInChip * parsedGap + dismissibleWidth + dotWidth + prefixWidth;
      return textAvailableSpace;
    }
    return 0;
  }

  /**
   * Close chip
   * 1. Set isDismissed to true to hide the chip
   * 2. Dispatch a custom event on close
   */
  public close(e: MouseEvent | KeyboardEvent) {
    e.stopPropagation();
    const target = e.target;
    if (this.isDisabled) {
      return false;
    }
    this.isDismissed = true; /* 1 */
    const chipButton = this.shadowRoot.querySelector(`#${this.cid}`);
    let chipContent = '';
    let isTextOverflowCase = false;
    if (this.content && this.textOverflow && chipButton) {
      chipContent = `${chipButton.textContent}`.replace(/\s+/g, ' ').trim();
      isTextOverflowCase = true;
    } else {
      const textNodeContent = this.chipText || chipButton?.querySelector('slot')?.assignedNodes()?.[0]?.textContent?.replace(/\s+/g, ' ')?.trim();
      if (typeof textNodeContent === 'string') {
        chipContent = textNodeContent;
      }
    }
    this.dispatch({
      eventName: 'close',
      detailObj: { chip: target, chipContent: chipContent, dataInfo: this.dataInfo || '', isTextOverflowCase }
    }); /* 2 */
  }

  /**
   * 1. Stop propogation only if chip is clicked, not if components inside are clicked.
   * @param e
   * @returns
   */
  handleChipClick(e: MouseEvent | KeyboardEvent) {
    if ((e.currentTarget as HTMLElement)?.classList?.contains('en-c-chip') && !(e.target as HTMLElement)?.classList?.contains('en-c-icon')) {
      /* 1. */
      e.stopPropagation();
    }
    if (this.isDisabled || !this.isClickable) {
      return false;
    }
    this.dispatch({ eventName: 'click' }); /* 2 */
  }

  /**
   * Handle on keydown events
   * 1. If the escape key is pressed, then close the chip
   */
  handleOnKeydown(e: KeyboardEvent) {
    /* 1 */
    if (e.code === 'Escape' && this.isDismissible) {
      this.close(e);
    } else if (e.code === 'Enter') {
      this.handleChipClick(e);
    }
  }

  render() {
    const isColorVariant = this.colorVariants.includes(this.variant);
    const componentClassNames = this.componentClassNames('en-c-chip', {
      'en-c-chip--secondary': this.secondaryVariants.includes(this.variant),
      'en-c-chip--tertiary': this.variant === 'tertiary',
      'en-c-chip--green': this.variant === 'green',
      'en-c-chip--red': this.variant === 'red',
      'en-c-chip--blue': this.variant === 'blue',
      'en-c-chip--amber': this.variant === 'amber',
      'en-c-chip--purple': this.variant === 'purple',
      'en-c-chip--neutral': this.variant === 'neutral',
      'en-c-chip--squared': this.type === 'squared',
      'en-is-disabled': this.isDisabled === true,
      'en-not-clickable': this.isClickable === false,
      'en-is-dismissible': this.isDismissible,
      'en-is-dismissed': this.isDismissed
    });

    /* Text overflow logic is expecting prefix slot to come before label slot and there exist not slot between them.
    If you require to change this, text overflow logic also have to be handled.*/
    return html`
      <button
        id="${this.cid}"
        class="${componentClassNames}"
        @keydown=${this.handleOnKeydown}
        @click=${this.handleChipClick}
        ?disabled=${this.isDisabled}
        data-info=${this.dataInfo}
      >
        ${this.slotNotEmpty('prefix') && html`<span class="en-c-button__icon"><slot name="prefix"></slot></span>`}
        ${isColorVariant ? html`<div class="en-c-chip__colordot"></div>` : html``}
        ${!!this.tooltipText && !!this.chipText && !(this.content && this.textOverflow)
          ? html`<${this.tooltipEl} cssPosition="${this.setTooltipCSSPositionFixed ? 'fixed' : 'absolute'}" .isDynamic=${true} .enableTextWrap=${true}
            ><span slot="trigger">${this.chipText}</span>
            ${this.tooltipText}
          </${this.tooltipEl}>`
          : this.content && this.textOverflow
            ? this.content
            : html`<slot></slot>`}
        ${this.isDismissible ? html` <${this.iconCloseEl} class="en-c-chip__close" @click=${this.close}></${this.iconCloseEl}>` : html``}
      </button>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENChip.el) === undefined) {
  customElements.define(ENChip.el, ENChip);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-chip': ENChip;
  }
}
