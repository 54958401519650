import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import styles from './badge.scss';

/**
 * Component: en-badge
 * - Badges are labels which hold small amounts of information. Badges can hold a number and can be paired with icons, text, or avatars.
 * @slot - The component content
 */
export class ENBadge extends ENElement {
  static el = 'en-badge';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * isDot boolean
   * - Makes the badge a small dot only
   */
  @property({ type: Boolean })
  isDot?: boolean;

  /**
   * isIcon boolean
   * - If true, add icon related style on badge otherwise text related
   */
  @property({ type: Boolean })
  isIcon?: boolean = false;

  /**
   * State variant
   * - **default** Displays a badge with the default state
   * - **success** renders a badge with success state treatment
   * - **warning** renders a badge with warning state treatment
   * - **danger** renders a badge with danger state treatment
   * - **accent** renders a badge with accent(purple theme color) state treatment
   * - **neutral** renders a badge with neutral state treatment
   * - **info** renders a badge with info(blue color) state treatment
   * Default is default
   */
  @property()
  variant?: 'default' | 'success' | 'warning' | 'danger' | 'neutral' | 'accent' | 'info' = 'default';

  /**
   * Positions the badge absolutely to the parent container.
   * - **top-left** places the badge to the top left
   * - **top-right** places the badge to the top right
   * - **bottom-left** places the badge to the bottom left
   * - **bottom-right** places the badge to the bottom right
   */
  @property()
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

  /**
   * First updated lifecycle hook
   * 1. Handle the position of the parent element
   */
  firstUpdated() {
    this.handlePosition();
  }

  /**
   * Handle position
   * 1. If a position is defined, then apply position: relative to the direct parent element
   */
  handlePosition() {
    if (this.position) {
      this.parentElement.style.position = 'relative';
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-badge', {
      'en-c-badge--info': this.variant === 'info',
      'en-c-badge--accent': this.variant === 'accent',
      'en-c-badge--success': this.variant === 'success',
      'en-c-badge--warning': this.variant === 'warning',
      'en-c-badge--danger': this.variant === 'danger',
      'en-c-badge--neutral': this.variant === 'neutral',
      'en-c-badge--top-left': this.position === 'top-left',
      'en-c-badge--top-right': this.position === 'top-right',
      'en-c-badge--bottom-left': this.position === 'bottom-left',
      'en-c-badge--bottom-right': this.position === 'bottom-right',
      'en-is-icon': this.isIcon,
      'en-is-dot': this.isDot
    });

    return html`
      <div class="${componentClassNames}">
        <slot></slot>
      </div>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENBadge.el) === undefined) {
  customElements.define(ENBadge.el, ENBadge);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-badge': ENBadge;
  }
}
