import { TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconInfo } from '../icon/icons/info';
import { ENRadio } from '../radio/radio';
import { ENTooltip } from '../tooltip/tooltip';
import styles from './radio-item.scss';

/**
 * Component: en-radio-item
 * - A radio item is a singular radio that is meant to be used within the ENRadio
 * @slot - The component content that appears next to the radio
 * @slot "field-note" - If content is slotted, it will display in place of the fieldNote property
 * @slot "error" - If content is slotted, it will display in place of the errorNote property
 */
export class ENRadioItem extends ENElement {
  static el = 'en-radio-item';

  private elementMap = register({
    elements: [
      [ENRadioItem.el, ENRadioItem],
      [ENFieldNote.el, ENFieldNote],
      [ENRadio.el, ENRadio],
      [ENIconInfo.el, ENIconInfo],
      [ENTooltip.el, ENTooltip]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private iconInfoEl = unsafeStatic(this.elementMap.get(ENIconInfo.el));
  private tooltipEl = unsafeStatic(this.elementMap.get(ENTooltip.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Checked attribute
   */
  @property({ type: Boolean })
  isChecked?: boolean;

  /**
   * Error state
   * - Changes the component's treatment to represent an error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only. This is different from the
   * disabled state, where the element is entirely removed from focus and interaction.
   */
  @property({ type: Boolean })
  isReadonly?: boolean;

  /**
   * Required attribute
   * - Sets the radio to be required for validation
   */
  @property({ type: Boolean })
  isRequired?: boolean;

  /**
   * Name attribute
   */
  @property()
  name?: string;

  /**
   * Value attribute
   */
  @property()
  value?: string;

  /**
   * Hide label?
   * - If true, hides the label from displaying
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   *  Error message
   * - An error field note that displays below the radio input
   */
  @property()
  errorNote?: string;

  /**
   * Field note
   * - The helper text that displays below the radio input
   */
  @property()
  fieldNote?: string;

  /**
   * Id attribute
   * - The ID used for A11y and to associate the label with the input
   */
  @property()
  fieldId?: string;

  /**
   * aria-describedby attribute
   * - Applied to the field note or error note for A11y
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * If tooltipInfo will be set then info icon will be shown with label. But if your are providing tooltipInfo then please also provide label.
   */
  @property()
  tooltipInfo?: string;

  /**
   * Icon size
   * - **sm** size is 16px
   * - **md** renders a larger size than sm (20px)
   * - **lg** renders a larger size than the md variant (24px)
   * - **xl** renders a larger size than the lg variant (32px)
   */
  @property()
  infoIconSize?: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  /**
   * Connected callback
   * 1. Dynamically sets the fieldId and ariaDescribedBy for A11y
   */
  connectedCallback() {
    super.connectedCallback();
    /* 1 */
    this.fieldId = this.fieldId || nanoid();
    if (this.fieldNote) {
      this.ariaDescribedBy = this.ariaDescribedBy || nanoid();
    }
  }

  /**
   * Remove checked
   * 1. Remove checked property from all radio items
   */
  removeChecked() {
    if (this.parentNode.nodeName === this.elementMap.get(ENRadio.el).toUpperCase()) {
      const radioItems = this.parentNode.querySelectorAll(this.elementMap.get(ENRadioItem.el));
      if (radioItems) {
        radioItems.forEach((el: ENRadioItem) => {
          el.isChecked = false;
        });
      }
    }
  }

  /**
   * Handle click events to prevent changes when readonly
   */
  handleOnClick(event: Event) {
    if (this.isReadonly) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  /**
   * Handle on change events
   * 1. Remove any checked items
   * 2. Toggle the checked state
   * 3. Dispatch the custom event
   */
  handleOnChange() {
    this.removeChecked(); /* 1 */
    this.isChecked = !this.isChecked; /* 2 */
    /* 3 */
    this.dispatch({
      eventName: 'change',
      detailObj: {
        checked: this.isChecked,
        name: this.name,
        value: this.value
      }
    });
  }

  /**
   * Set the next or previous sibling as checked based on the arrow key pressed
   * @param {string} direction - 'previousElementSibling' or 'nextElementSibling'
   * 1. Skip over disabled siblings
   * 2. Remove checked from all items
   * 3. Set the sibling as checked
   * 4. Focus on the selected sibling's radio input
   * 5. Dispatch the custom event
   */
  setSiblingChecked(direction: 'previousElementSibling' | 'nextElementSibling') {
    let sibling = this[direction] as ENRadioItem | null;

    /* 1 */
    while (sibling && sibling.isDisabled) {
      sibling = sibling[direction] as ENRadioItem | null;
    }

    if (sibling.nodeName === this.elementMap.get(ENRadioItem.el).toUpperCase()) {
      this.removeChecked(); /* 2 */
      sibling.isChecked = true; /* 3 */

      /* 4 */
      const siblingRadioInput = sibling.shadowRoot?.querySelector<HTMLInputElement>('.en-c-radio-item__input:not(:disabled)');
      if (siblingRadioInput) {
        siblingRadioInput.focus();
      }

      /* 3 */
      this.dispatch({
        eventName: 'change',
        detailObj: {
          checked: sibling.isChecked,
          name: sibling.name,
          value: sibling.value
        }
      });
    }
  }

  /**
   * Handle on keydown events
   * 1. If the enter key is pressed, then check the checkbox and dispatch the custom event
   * 2. If arrow left or arrow up is pressed, set previous sibling as checked
   * 3. If arrow right or arrow down is pressed, set next sibling as checked
   */
  handleOnKeydown(e: KeyboardEvent) {
    const isPreviousSiblingKey = e.code === 'ArrowLeft' || e.code === 'ArrowUp';
    const isNextSiblingKey = e.code === 'ArrowRight' || e.code === 'ArrowDown';
    const isSelectKey = e.code === 'Enter';

    const isChangePrevented = this.isReadonly && (isSelectKey || isPreviousSiblingKey || isNextSiblingKey);
    if (isChangePrevented) {
      e.preventDefault();
      return;
    }

    if (isSelectKey) {
      this.handleOnChange(); /* 1 */
    } else if (isPreviousSiblingKey) {
      this.setSiblingChecked('previousElementSibling'); /* 2 */
    } else if (isNextSiblingKey) {
      this.setSiblingChecked('nextElementSibling'); /* 3 */
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-radio-item', {
      'en-is-error': this.isError === true,
      'en-is-disabled': this.isDisabled === true,
      'en-has-hidden-label': this.hideLabel
    });

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-radio-item__container">
          <div class="en-c-radio-item__radio">
            <input
              class="en-c-radio-item__input"
              type="radio"
              id="${this.fieldId}"
              name="${this.name}"
              .value="${this.value}"
              .checked="${this.isChecked}"
              ?disabled="${this.isDisabled}"
              ?required=${this.isRequired}
              @change=${this.handleOnChange}
              @click=${this.handleOnClick}
              @keydown=${this.handleOnKeydown}
              aria-describedby="${ifDefined(this.ariaDescribedBy)}"
              tabindex="0"
            />
            <span class="en-c-radio-item__custom-radio"></span>
            <span class="en-c-radio-item__ripple"></span>
          </div>
          <div class="${!!this.tooltipInfo ? 'en-label-info' : ''}">
            <label class="en-c-radio-item__label" for="${this.fieldId}">
              <slot></slot>
            </label>
            ${!!this.tooltipInfo
              ? html`<${this.tooltipEl}>
          <${this.iconInfoEl} size="${this.infoIconSize}" slot="trigger"></${this.iconInfoEl}>
        ${this.tooltipInfo}
        </${this.tooltipEl}>`
              : html``}
          </div>
        </div>
        ${(this.fieldNote || this.slotNotEmpty('field-note')) &&
        html`
          <slot name="field-note">
            <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
          </slot>
        `}
        ${(this.errorNote || this.slotNotEmpty('error')) &&
        html`
          <slot name="error">
            <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
          </slot>
        `}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENRadioItem.el) === undefined) {
  customElements.define(ENRadioItem.el, ENRadioItem);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-radio-item': ENRadioItem;
  }
}
