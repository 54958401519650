import { useState } from "react"
import AppHeaderLayout from "src/shared/components/AppHeaderLayout"
import PageHeader, { PageHeaderType } from "src/shared/components/PageHeader"
import { useApplicationPolicyEvaluationStyles } from "./ApplicationPolicyEvaluation.styles"
import ApplicationPolicyEvaluationForm from "./ApplicationPolicyEvaluationForm"
import ApplicationTroubleshooting from "./ApplicationTroubleshooting"
import PolicyEvaluationResult from "./PolicyEvaluationResult"

const ApplicationPolicyEvaluation: React.FC = () => {
  const classes = useApplicationPolicyEvaluationStyles()
  const [fetchEvaluationResult, setFetchEvaluationResult] = useState(false)
  const [evaluationId, setEvaluationId] = useState<null | string>(null)
  const [isAgentless, setIsAgentless] = useState(false)
  const [isFetchingEvaluationResult, setIsFetchingEvaluationResult] = useState(false)
  const [isDeviceUnselected, setIsDeviceUnselected] = useState(true)
  const [isNoActivePolicy, setIsNoActivePolicy] = useState(true)

  const pageHeaderProps: PageHeaderType = {
    title: "Policy Evaluation & Troubleshooting",
    marginBottom: 17.5,
  }

  return (
    <>
      <AppHeaderLayout
        slots={{
          pageHeader: <PageHeader noPortal title={pageHeaderProps.title} />,
        }}
      />

      <div className={classes.root}>
        <ApplicationPolicyEvaluationForm
          isFetchingEvaluationResult={isFetchingEvaluationResult}
          setIsFetchingEvaluationResult={setIsFetchingEvaluationResult}
          setFetchEvaluationResult={setFetchEvaluationResult}
          setEvaluationId={setEvaluationId}
          setIsAgentless={setIsAgentless}
          setIsDeviceUnselected={setIsDeviceUnselected}
        />

        {evaluationId && (
          <>
            <PolicyEvaluationResult
              fetchEvaluationResult={fetchEvaluationResult}
              setFetchEvaluationResult={setFetchEvaluationResult}
              setIsFetchingEvaluationResult={setIsFetchingEvaluationResult}
              setIsNoActivePolicy={setIsNoActivePolicy}
            />

            <ApplicationTroubleshooting
              evaluationId={evaluationId}
              isAgentless={isAgentless}
              isFetchingEvaluationResult={isFetchingEvaluationResult}
              isNoActivePolicy={isNoActivePolicy}
              isDeviceUnselected={isDeviceUnselected}
            />
          </>
        )}
      </div>
    </>
  )
}

export default ApplicationPolicyEvaluation
