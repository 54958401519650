import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ENElement } from '../../ENElement';
import styles from '../icon.scss';
import Menu from '../svgs/menu.svg';

/**
 * Icon: en-icon-menu
 */
export class ENIconMenu extends ENElement {
  static el = 'en-icon-menu';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Icon title
   */
  @property()
  iconTitle?: string;

  /**
   * Icon size
   * - Default size is 16px
   * - **md** renders a larger size than default (20px)
   * - **lg** renders a larger size than the md variant (24px)
   * - **xl** renders a larger size than the lg variant (32px)
   */
  @property()
  size?: 'md' | 'lg' | 'xl';

  connectedCallback() {
    super.connectedCallback();
    this.classList.add('en-c-icon');
  }

  render() {
    const componentClassName = this.componentClassNames('en-c-icon-menu', {
      'en-c-icon--md': this.size === 'md',
      'en-c-icon--lg': this.size === 'lg',
      'en-c-icon--xl': this.size === 'xl'
    });

    return html`
      <span
        aria-hidden="${!this.iconTitle}"
        aria-labelledby="${this.iconTitle}"
        class="${componentClassName}"
        role="${this.iconTitle ? 'img' : 'presentation'}"
      >
        ${Menu}
      </span>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENIconMenu.el) === undefined) {
  customElements.define(ENIconMenu.el, ENIconMenu);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-icon-menu': ENIconMenu;
  }
}
