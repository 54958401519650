import { TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENDropdownPanel } from '../dropdown-panel/dropdown-panel';
import { ENFieldNote } from '../field-note/field-note';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconClose } from '../icon/icons/close';
import { ENListItem } from '../list-item/list-item';
import { ENList } from '../list/list';
import { ENLoadingIndicator } from '../loading-indicator/loading-indicator';
import { ENSearchForm } from '../search-form/search-form';
import { ENTextField } from '../text-field/text-field';
import { ENCheckboxItem } from './../checkbox-item/checkbox-item';
import { PartialDataSource } from './dropdown.model';
import styles from './dropdown.scss';

/**
 *  @slot - The component content
 */
export class ENDropdown extends ENElement {
  static el = 'en-dropdown';

  private elementMap = register({
    elements: [
      [ENDropdownPanel.el, ENDropdownPanel],
      [ENFieldNote.el, ENFieldNote],
      [ENButton.el, ENButton],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENIconClose.el, ENIconClose],
      [ENTextField.el, ENTextField],
      [ENSearchForm.el, ENSearchForm],
      [ENList.el, ENList],
      [ENListItem.el, ENListItem],
      [ENCheckboxItem.el, ENCheckboxItem],
      [ENLoadingIndicator.el, ENLoadingIndicator]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private dropdownPanelEl = unsafeStatic(this.elementMap.get(ENDropdownPanel.el));
  private fieldNoteEl = unsafeStatic(this.elementMap.get(ENFieldNote.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private iconCloseEl = unsafeStatic(this.elementMap.get(ENIconClose.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private textFieldEl = unsafeStatic(this.elementMap.get(ENTextField.el));
  private searchFormEl = unsafeStatic(this.elementMap.get(ENSearchForm.el));
  private listItemEl = unsafeStatic(this.elementMap.get(ENListItem.el));
  private listEl = unsafeStatic(this.elementMap.get(ENList.el));
  private loadingIndicatorEl = unsafeStatic(this.elementMap.get(ENLoadingIndicator.el));
  private debounceSeed: number = null;

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * is Active
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * is Active dropdown
   * 1. Dropdown is open when set to true. Close when set to false
   */
  @property({ type: Boolean })
  isActiveDropdown?: boolean;

  /**
   * The unique id of the select field
   */
  @property()
  fieldId?: string;

  /**
   * The select field's title
   */
  @property()
  title: string;

  /**
   * The select field's label
   */
  @property()
  label = 'Label';

  /**
   * Dropdown Datasource
   */
  @property()
  dataSource: Array<PartialDataSource>;

  /**
   * The select field's name attribute
   */
  @property()
  name?: string;

  /**
   * The select field's value attribute
   */
  @property()
  value?: string;

  /**
   * Placeholder attribute
   * - Specifies a short hint that describes the expected value of an <input> element
   */
  @property()
  placeholder?: string;

  /**
   * The dropdown field note
   */
  @property()
  fieldNote?: string;

  /**
   * **exactSearchMatch**
   * - If true, match exactly otherwise search case insensitive. Default is false.
   */
  @property()
  exactSearchMatch?: boolean = false;

  /**
   * **debounceIntervalInMilliSecond**
   * - You can increase or reduce debounce interval in search input. Default is 100.
   */
  @property()
  debounceIntervalInMilliSecond?: number = 100;

  /**
   * The dropdown error note
   */
  @property()
  errorNote?: string;

  /**
   * Aria describedby
   * 1. Used to connect the field note in select field to the select menu for accessibility
   */
  @property()
  ariaDescribedBy?: string;

  /**
   * The select field's required attribute
   */
  @property({ type: Boolean })
  isRequired?: boolean = false;

  /**
   * Optional state
   * - Specifies that a field is optional and adds the text 'optional' to the label
   */
  @property({ type: Boolean })
  isOptional?: boolean;

  /**
   * If true enables lazy loading, i.e data will be loaded lazily as user scroll down. Default is false
   */
  @property({ type: Boolean })
  enableLazyLoading?: boolean = false;

  /* If true enables server side search. Default is false. */
  @property({ type: Boolean })
  enableServerSideSearch?: boolean = false;

  /* If true enable loader on lazy loading. Default is true. */
  @property({ type: Boolean })
  showLoaderOnLazyLoading?: boolean = true;

  /**
   * Set this property to false to show large loader. Default is true
   */
  @property({ type: Boolean })
  showSmallLoader?: boolean = true;

  /**
   * Set this property for showing loader in light theme. Default is false.
   */
  @property({ type: Boolean })
  showInvertedLoader?: boolean = false;

  /**
   * Expects asynchronous function if `enableLazyLoading` is set that
   * - Receive page number and search string
   * - Calls API
   * - Returns data in shape of Array<{label:string, value:string}>
   * where value must be unique. Default is null.
   */
  @property()
  lazyLoadingService: (page: number, search: string) => Promise<Array<PartialDataSource>> = null;

  /**
   * The select field's disabled attribute
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Error state
   */
  @property({ type: Boolean })
  isError?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only
   */
  @property({ type: Boolean })
  isReadonly?: boolean = true;

  /**
   * searchInputPlaceholder
   * - Placeholder to be shown on search box input. If not set then search box default placeholder will be shown
   */
  @property()
  searchInputPlaceholder?: string = 'Type to filter result';

  /**
   * Hide the label?
   */
  @property({ type: Boolean })
  hideLabel?: boolean;

  /**
   * Add a search input to the dropdown panel
   */
  @property({ type: Boolean })
  hasSearch?: boolean = false;

  /**
   * If true, then add cross icon on dropdown which enables clearing selection. Default value is false.
   */
  @property({ type: Boolean })
  enableClearSelection?: boolean = false;

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * Default is bottom. Releavance of setting it is only when `enableDynamicPositioning` is set to false.
   */
  @property()
  align?: 'bottom' | 'top' = 'bottom';

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = true;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * This property purpose is use within shadow DOM
   */
  @property()
  dropdownPanelContainerShadowDomElement: HTMLElement;

  @property({ type: Number })
  lazyLoadingScrollOffset = 0;

  @state()
  activeElementLabel: string;

  /**
   * To record searched value
   */
  @state()
  searchInputValue: string = '';

  /**
   * Variant
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary';

  /* Active item */
  private activeElement: ENListItem;

  /* To record last scroll position. It is used to control logic execution on up scroll */
  private _lastListScroll: number;

  /* When end is met, then this property helps to disable scroll event execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockScrollEvent: boolean = false;

  /* When end of search result is met, then this property helps to disable scroll event execution till time new results are not loaded */
  /* This prevents multiple API calls */
  private _lockSearchScrollEvent: boolean = false;

  /**
   * Stores the last loaded page. Initially it is 1 because first page data is expected from developer to pass in
   * web component.
   */
  private _lazyLoadingPage: number = 1;

  /**
   * Stores the last search result page. Initially it is 0 because we expect no search. It will be incremented only in case
   * scroll happens while search box have some search string
   */
  private _searchResultPage: number = 0;

  @state()
  private _setDropdownLoader: boolean = false;

  /**
   * Query the list element inside dropdown panel
   */
  get dropdownPanelBlock(): HTMLDivElement {
    const dropdownPanel = this.shadowRoot.querySelector<ENDropdownPanel>('.en-c-dropdown__panel');
    const dropdownPanelBlock = dropdownPanel.shadowRoot.querySelector<HTMLDivElement>('.en-c-dropdown-panel__body');
    return dropdownPanelBlock;
  }

  /**
   * True if slot change event is attached. Otherwise false.
   */
  private _slotChangeEventAttached: boolean = false;

  /**
   * Query all the list items
   */
  get listItems(): Array<ENListItem> {
    if (this.enableLazyLoading) {
      const dropdownPanel = this.shadowRoot.querySelector<ENDropdownPanel>('.en-c-dropdown__panel');
      const listItems = dropdownPanel.querySelectorAll<ENListItem>(this.elementMap.get(ENListItem.el));
      return [...listItems];
    }
    return [...this.querySelectorAll<ENListItem>(this.elementMap.get(ENListItem.el))];
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  /**
   * Connected Callback lifecycle
   * 1. Close dropdown panel when you click outside of the element
   * 2. Autogenerate the fieldID
   */
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mousedown', this.handleOnClickOutside, false); /* 1 */
    this.fieldId = this.fieldId || nanoid(); /* 2 */
  }

  /**
   * Disconnected callback lifecycle
   * 1. Remove event listeners
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mousedown', this.handleOnClickOutside, false);
  }

  /**
   * Handle auto expand(isActiveDropdown : true default)
   */
  firstUpdated() {
    if (this.isActiveDropdown) {
      this.handleOnActiveDropdown();
    }
    setTimeout(() => {
      const list = this.querySelector<ENList>(this.elementMap.get(ENList.el));
      const listSlot = (list?.shadowRoot?.querySelector('.en-c-list')?.children?.[0] as HTMLSlotElement) || null;
      if (!!listSlot && !this._slotChangeEventAttached) {
        const dropdownThis = this;
        listSlot.addEventListener('slotchange', () => {
          setTimeout(() => {
            dropdownThis.addClickHandlers();
          }, 0);
        });
        this._slotChangeEventAttached = true;
      }
      this.addClickHandlers();
    }, 1);

    if (this.enableLazyLoading) {
      setTimeout(() => {
        this._attachScrollEventToList();
      }, 0);
    }
  }

  /**
   * Merge new Data(loaded from API) with source ensuring repeating entries not loaded again.
   * 1. If API returns no data, then return source as it is assigning it new reference
   * 2. Create a dictionary or mapping of dropdown items value and dropdown items.
   * 3. If value in new data already exist in mapping created in step 2, it means that item already exist, then don't load it again
   * @param source Exisisting Datasource
   * @param newData New data loaded from API
   * @returns Merged data such that data has no entry that has repeating value
   */
  private _mergeNewLoadedData = (source: Array<PartialDataSource>, newData: Array<PartialDataSource>) => {
    if (!Array.isArray(newData) || newData.length === 0) {
      /* 1 */
      return [...source];
    }
    const sourceOb: { [key: string]: PartialDataSource } = {};
    for (const item of source) {
      /* 2 */
      sourceOb[`${item.value}`] = item;
    }
    const result = [...source];
    for (const item of newData) {
      const itemValue = `${item.value}`;
      if (itemValue in sourceOb) {
        /* 3 */
        continue;
      }
      result.push(item);
    }
    return result;
  };

  /**
   * Handle Lazy loading
   * 1. Enabling Loader
   * 2. Calling data loading service
   * 3. If API stops returning data, then if it happen for search result, then it is possible that data is there but not for search result so set search based lock otherwise set permanent lock
   * 4. Merge api data with exisisting data.
   * 5. Calling addClickHandler to attach click event with newly added list items
   * 6. Removing lock from scroll event
   * 7. Handling service error and dispatch error event if service fails
   * 8. Removing loader
   * 9. Apply lock on search scroll if search result is empty but remove lock from main scroll because we are returning from there itself. Otherwise main scroll lock will be removed after dispatch.
   */
  private _handleLazyLoading = (searchFn: Function = () => {}) => {
    console.log('2. Setting lock on scroll event...');
    this._lockScrollEvent = true;
    if (this.lazyLoadingService) {
      /* 1 */
      console.log('3. Enabling Loader...');
      this._setDropdownLoader = true;
      const applySearchChecks = this.enableServerSideSearch && !!this.searchInputValue;
      /* 2 */
      console.log('4. Calling lazy loading service...');
      this.lazyLoadingService(
        applySearchChecks ? this._searchResultPage + 1 : this._lazyLoadingPage + 1,
        applySearchChecks ? this.searchInputValue || '' : ''
      )
        .then((data: Array<PartialDataSource>) => {
          if (!data || data.length === 0) {
            console.log('5. *Data returned is empty, thus assuming that there is no more data and permanenty disabling scroll event');
            /* 3 */
            if (applySearchChecks) {
              /* 9 */
              this._lockSearchScrollEvent = true;
              this._lockScrollEvent = false;
            } else {
              this._lockScrollEvent = true;
            }
            return;
          }
          console.log('6. Merging API data with exisisting data');
          /* 4 */
          this.dataSource = this._mergeNewLoadedData(this.dataSource, data);
          if (applySearchChecks) {
            this._searchResultPage = this._searchResultPage + 1;
          } else {
            this._lazyLoadingPage = this._lazyLoadingPage + 1;
          }
          console.log('7. Dispatching lazyLoadingServiceSuccess event');
          this.dispatch({
            eventName: 'lazyLoadingServiceSuccess',
            detailObj: {
              apiData: [...data],
              dataSource: [...this.dataSource],
              lastScrollTop: this._lastListScroll,
              page: this._lazyLoadingPage,
              searchResultPage: this._searchResultPage,
              applySearchChecks,
              search: this.searchInputValue
            }
          });
          setTimeout(() => {
            /* 5 */
            console.log('9. Attaching click handlers and processing searched items...');
            this.addClickHandlers();
            if (searchFn) {
              searchFn();
            }
          }, 1);
          /* 6 */
          this._lockScrollEvent = false;
        })
        .catch((err) => {
          /* 7 */
          console.error('Lazy Loading Service Failed with error: ', err);
          this.dispatch({
            eventName: 'lazyLoadingServiceFailed',
            detailObj: { err, lastScrollTop: this._lastListScroll, page: this._lazyLoadingPage + 1, search: this.searchInputValue }
          });
        })
        .finally(() => {
          /* 8 */
          console.log('8. Disabling Loader...');
          this._setDropdownLoader = false;
        });
    }
  };

  /**
   * Attach scroll event to list
   * 1. If lock is set on scroll event, then disable scroll event execution. Lock is set during API call or when API stop returning data
   * 2. If user scrolling upwards, then no need to execute scroll event further
   * 3. Call lazy loading handler
   */
  private _attachScrollEventToList = () => {
    const dropdownPanelBlock = this.dropdownPanelBlock;
    dropdownPanelBlock.addEventListener('scroll', (evt: Event) => {
      if ((this.enableServerSideSearch && !!this.searchInputValue && this._lockSearchScrollEvent) || this._lockScrollEvent) {
        /* 1 */
        return;
      }
      const element = evt.target as HTMLDivElement;
      if (element.scrollTop < this._lastListScroll) {
        /* 2 */
        return;
      }
      this._lastListScroll = element.scrollTop <= 0 ? 0 : element.scrollTop;
      if (element.scrollTop + element.offsetHeight >= element.scrollHeight - this.lazyLoadingScrollOffset) {
        /* 3 */
        console.log('1. Scroll meets end. Calling lazy loading handler...');
        this._handleLazyLoading();
      }
    });
  };

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is 'value' and it has been modified.
   * 3. Find active list item corresponding to selected value and make it active.
   * 4. If user type label, then automatically list item corresponding to that label should be selected
   * 5. If value matches with no dropdown item and is empty then clear selection
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    /* 1 */
    changedProperties.forEach((oldValue, propName) => {
      /* 2 */
      if (propName === 'value' && this.value !== oldValue) {
        let atLeastOneValueMatch = false;
        this.listItems.forEach((element: ENListItem) => {
          /* 3 */
          const elementValue = typeof element.value !== 'object' ? element.value : element.value?.value;
          if (elementValue === this.value) {
            atLeastOneValueMatch = true;
            this.activeElementLabel = element.textContent;
            this.isActive = true;
            this.activeElement = element;
            const notActive = this.listItems.filter((item: HTMLElement) => item !== element);
            notActive.forEach((item: ENListItem) => {
              item.isActive = false;
            });
            element.isActive = true;
          }
        });
        if (!atLeastOneValueMatch && this.value === '') {
          /* 5 */
          this.value = '';
          this.activeElementLabel = '';
          this.isActive = false;
          if (this.isActiveDropdown) {
            this.toggleActive();
          }
          if (this.activeElement) {
            this.activeElement.isActive = false;
          }
        }
      }
      if (!this.isReadonly && propName === 'activeElementLabel' && this.activeElementLabel !== oldValue) {
        this.listItems.forEach((element: ENListItem) => {
          /* 4 */
          const listItemText = `${element.innerText}`;
          const elementValue = typeof element.value !== 'object' ? element.value : element.value?.value;
          if (listItemText === this.activeElementLabel) {
            this.activeElementLabel = listItemText;
            this.isActive = true;
            this.activeElement = element;
            const notActive = this.listItems.filter((item: HTMLElement) => item !== element);
            notActive.forEach((item: ENListItem) => {
              item.isActive = false;
            });
            element.isActive = true;
            this.value = elementValue;
          }
        });
      }
    });
  }

  /**
   * Handle click outside the component
   * 1. Close the show hide panel on click outside
   * 2. If the nav is already closed then we don't care about outside clicks and we
   * can bail early
   * 3. By the time a user clicks on the page the shadowRoot will almost certainly be
   * defined, but TypeScript isn't that trusting and sees this.shadowRoot as possibly
   * undefined. To work around that we'll check that we have a shadowRoot (and a
   * rendered .host) element here to appease the TypeScript compiler. This should never
   * actually be shown or run for a human end user.
   * 4. Check to see if we clicked inside the active panel
   * 5. If the panel is active and we've clicked outside of the panel then it should
   * be closed.
   */
  handleOnClickOutside(event: MouseEvent) {
    /* 2 */
    if (!this.isActiveDropdown) {
      return;
    }
    /* 3 */
    if (!this.shadowRoot?.host) {
      throw Error('Could not determine panel context during click handler');
    }
    /* 4 */
    const didClickInside = event.composedPath().includes(this.shadowRoot.host);
    /* 5 */
    if (this.isActiveDropdown && !didClickInside) {
      this.toggleActive();
    }
  }

  /**
   * Set menu active state
   * 1. Toggle the active state between true and false
   */
  toggleActive() {
    this.isActiveDropdown = !this.isActiveDropdown; /* 1 */
    // if (this.activeElement) {
    //   this.activeElement.isActive = false;
    // }
    if (this.isActiveDropdown === true) {
      this.handleOnActiveDropdown();
      this.dispatch({ eventName: 'open', detailObj: { active: true } });
    } else {
      this.shadowRoot?.querySelector<HTMLInputElement>('.en-c-dropdown__input')?.focus();
      this.dispatch({ eventName: 'close', detailObj: { active: false } });
    }
  }

  /**
   * Handles the activation behavior of the dropdown:
   * 1. Positions the dropdown panel based on available viewport space
   * 2. Sets focus to the first element in the dropdown panel or the search box (if available) when active
   * 3. When the dropdown is active:
   *    3.1. If hasSearch is false and a value is available, focuses on the selected item & scrolls to view it
   *    3.2. If hasSearch is true and a value is available, focuses on the search box (no need to focus the active element) & scrolls to view it
   * 4. When the dropdown is active and no value is available:
   *    4.1. If hasSearch is false, focuses on the first non-disabled element
   *    4.2. If hasSearch is true, as the search box has focus, no need to focus the first non-disabled element
   */
  handleOnActiveDropdown() {
    setTimeout(() => {
      if (this.enableDynamicPositioning) {
        const dropdownPanel = this.shadowRoot.querySelector<HTMLElement>('.en-c-dropdown__panel')?.getBoundingClientRect();
        const body = !!this.dropdownPanelContainerShadowDomElement
          ? this.dropdownPanelContainerShadowDomElement
          : document.querySelector(this.dropdownPanelContainerSelector);
        const bodyPosition = body?.getBoundingClientRect();
        if (!!bodyPosition && bodyPosition.height > dropdownPanel?.height && dropdownPanel?.bottom > bodyPosition.bottom) {
          /* Position dropdown panel based on viewport space */
          this.align = 'top'; /* 1 */
        }
      }

      /* 2 */
      if (this.value) {
        /* Handle focus and scroll for a selected value */
        this.activeElement = this.dataSource
          ? (this.listItems[this.dataSource?.findIndex((obj) => obj.value === this.value)] as ENListItem)
          : this.activeElement;
        if (this.activeElement) {
          // this.activeElement.isActive = true;
          if (!this.hasSearch) {
            /* Focus on the selected item if hasSearch is false */
            (this.activeElement.shadowRoot.querySelector('.en-c-list-item__link') as HTMLAnchorElement).focus(); /* 3.1 */
          }
          // TODO: Commenting this scroll logic and adding scrollIntoView. Commenting and not removing it because want to confirm that develop doesn't face any side effect
          // Scroll to the Active item
          // const panel = this.shadowRoot.querySelector(this.elementMap.get(ENDropdownPanel.el)).shadowRoot.querySelector('.en-c-dropdown-panel__body');
          // const searchHeight = this.hasSearch
          //   ? this.shadowRoot.querySelector(this.elementMap.get(ENDropdownPanel.el)).shadowRoot.querySelector('.en-c-dropdown-panel__header')
          //       ?.clientHeight
          //   : 0;
          // panel.scrollTop = this.activeElement?.offsetTop - searchHeight || 0;
          // TODO: Remove above commented logic as logic for scroll to active item is not working in above logic. So added scrollIntoView logic
          this.activeElement?.scrollIntoView?.();
        }
      } else if (!this.hasSearch) {
        /* Focus on the first non-disabled element if no value and no search box */
        const firstNonDisabled = Array.from(this.listItems).find((item: ENListItem) => !item.isDisabled);
        firstNonDisabled?.shadowRoot.querySelector<HTMLButtonElement | HTMLAnchorElement>('.en-c-list-item__link').focus(); /* 4.1 */
      }
    }, 0);
  }

  /**
   * Handle on select input keydown
   * 1. If key selected is enter or spacebar, toggle the menu open/close
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleActive();
    }
  }

  /**
   * Handle on keydown
   * 1. If the panel is open and escape is keyed, close the menu and return focus to the trigger button
   * 2. Find the last item in the list. Set the last element to that item to define variable
   * 3. If the last element is defined, once Tab is selected after that the panel will close
   */
  handleOnKeydownDropdownPanel(e: KeyboardEvent) {
    if (this.isActiveDropdown === true && e.code === 'Escape') {
      this.toggleActive();
    }

    if (this.listItems) {
      let lastFocusableElement;
      for (let i = this.listItems.length - 1; i >= 0; i--) {
        if (!this.listItems[i].shadowRoot.querySelector('.en-c-list-item.en-is-disabled')) {
          lastFocusableElement = this.listItems[i];
          break;
        }
      }

      let lastElement;
      if (document.activeElement === lastFocusableElement) {
        lastElement = lastFocusableElement; /* 2 */
      }
      if (lastElement) {
        if (this.isActiveDropdown === true && e.code === 'Tab') {
          this.toggleActive(); /* 3 */
        }
      }
    }
  }

  /**
   * Lifecycle method triggered when the component is first updated on the page
   * 1. Attaches click handlers to ENListItem components within the dropdown that do not contain children
   * 2. If the component is not readonly, clears the input value
   * 3. If the component is not readonly, focuses on the dropdown after selecting an item
   * 4. Sets the dropdown value with the value of the selected list item
   * 5. Update active element to selected element.
   * 6. Ensures only the last selected item remains active
   * 7. If same value is selected again from dropdown then click handler will have no effect
   * 8. This evt.preventDefault is must because it is possible that more than 1 click event got attached with list item. So in order to trigger single click this event is necessary
   */
  addClickHandlers() {
    /* 1 */
    if (this.listItems) {
      this.listItems.forEach((element: ENListItem) => {
        const listItemTrigger = element?.shadowRoot.querySelector('.en-c-list-item__link');
        if (listItemTrigger) {
          listItemTrigger?.addEventListener('click', (evt: MouseEvent) => {

            /* 8 */
            evt.preventDefault();
            const newSelectedValue = typeof element.value !== 'object' ? element.value : element.value?.value; /* 4 */
            if (this.value === newSelectedValue) {
              /* 7 */
              return false;
            }
            this.shadowRoot.querySelector<HTMLInputElement>('.en-c-dropdown__input').value = ''; /* 2 */
            this.shadowRoot.querySelector<HTMLInputElement>('.en-c-dropdown__input').focus(); /* 3 */
            this.value = newSelectedValue;
            this.activeElementLabel = element.textContent;
            this.isActive = true;
            /* 5 */
            this.activeElement = element;
            /* 6 */
            const notActive = this.listItems.filter((item: HTMLElement) => item !== element);
            notActive.forEach((item: ENListItem) => {
              item.isActive = false;
            });
            element.isActive = true;
            this.dispatch({
              eventName: 'selectValue',
              detailObj: {
                selectedValue: this.value,
                selectedLabel: this.activeElementLabel,
                activeElement: element
              }
            });
          });
        }
      });
    }
  }

  /**
   * Change output binding
   * 1. If the input field is not readonly, then allow typing
   */
  handleOnChange(evt: CustomEvent) {
    let userTypedValue = evt?.detail?.value || '';
    this.value = userTypedValue;
    this.activeElementLabel = userTypedValue;
    if (this.activeElement) {
      this.activeElement.isActive = false;
      this.activeElement = null;
    }
  }

  /**
   * Handles close icon click
   * 1. Set value to empty.
   * 2. Set isActive to false, so that Label can come to original position.
   * 3. Close dropdown if it is open.
   * 4. Remove list item selection from dropdown.
   * 5. Dispatch clear dropdown event so that any other handling if required can be done by developer.
   * @param evt Mouse Click Event
   */
  handleCloseIconClick(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    if (!this.enableClearSelection) {
      return false;
    }

    /* 1 */
    const lastValue = this.value;
    this.value = '';
    const lastActiveValueLabel = this.activeElementLabel;
    this.activeElementLabel = '';
    /* 2 */
    this.isActive = false;
    /* 3 */
    if (this.isActiveDropdown) {
      this.toggleActive();
    }
    /* 4 */
    let lastActiveElement;
    if (this.activeElement) {
      lastActiveElement = this.activeElement;
      this.activeElement.isActive = false;
    }
    /* 5 */
    this.dispatch({
      eventName: 'clearDropdown',
      detailObj: { lastSelectedValue: lastValue, lastSelectedValueLabel: lastActiveValueLabel, lastSelectedListItem: lastActiveElement }
    });
  }

  /**
   * Handle search based list item filtering with debouncing in place
   * 1. Clear debounce seed which cancels old search operation.
   * 2. Initialize debounce seed to new search operation with 100ms default latency.
   * 3. If exactSearchMatch is disabled then search value is trimmed and lowered case to perform case insensitive search.
   * 4. If search value is empty then unhide all the list item.
   * 5. If search value is present then hide all the list item that not satisfy search criteria and unhide all search that satisfy search criteria.
   * 6. If label not matches search and there is search attribute on list then also look into it for value
   * @param evt Event
   */
  handleSearchFormChange(evt: any) {
    if (this.debounceSeed) {
      /* 1 */
      clearTimeout(this.debounceSeed);
      this.debounceSeed = null;
    }
    this.searchInputValue = evt.detail && evt.detail.value !== undefined && evt.detail.value !== null ? `${evt.detail.value}` : '';
    /* 2 */
    this.debounceSeed = setTimeout(() => {
      let searchValue = this.searchInputValue;
      if (searchValue && !this.exactSearchMatch) {
        /* 3 */
        searchValue = searchValue.trim();
        searchValue = searchValue.toLowerCase();
      }
      if (searchValue === '') {
        /* 4 */
        this.listItems.forEach((element: ENListItem) => {
          element.hide = false;
        });
        if (this.enableServerSideSearch) {
          this._searchResultPage = 0;
          this._lockSearchScrollEvent = false;
        }
      } else {
        /* 5 */
        const processSearchItems = () => {
          this.listItems.forEach((element: ENListItem) => {
            const itemLabel = `${element.textContent}`.replace(/\s+/g, ' ').trim();
            const valueToCompare = this.exactSearchMatch ? `${itemLabel}` : `${itemLabel}`.toLowerCase();
            let searchStrToCompare = '';
            if (!!element.search) {
              searchStrToCompare = this.exactSearchMatch ? `${element.search}` : `${element.search}`.toLowerCase();
            }
            if (valueToCompare.includes(searchValue) || searchValue?.includes(valueToCompare)) {
              element.hide = false;
            } else {
              if (!!searchStrToCompare && (searchStrToCompare.includes(searchValue) || searchValue?.includes(searchStrToCompare))) {
                /* 6 */
                element.hide = false;
              } else {
                element.hide = true;
              }
            }
          });
        };
        if (this.enableServerSideSearch) {
          console.log('1. Loading Server side search result...');
          this._handleLazyLoading(processSearchItems);
        } else {
          processSearchItems();
        }
      }
      this.dispatch({
        eventName: 'searchInputChange',
        detailObj: {
          value: this.searchInputValue,
          listItems: this.listItems
        }
      });
    }, this.debounceIntervalInMilliSecond);
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-dropdown', {
      'en-is-disabled': this.isDisabled,
      'en-is-required': this.isRequired,
      'en-is-error': this.isError,
      'en-is-active': this.isActive === true,
      'en-is-active-dropdown': this.isActiveDropdown === true,
      'en-has-search': this.hasSearch,
      'en-has-hidden-label': this.hideLabel === true,
      'en-c-dropdown--align-bottom': this.align === 'bottom',
      'en-c-dropdown--align-top': this.align === 'top'
    });

    if (this.variant === undefined) {
      this.variant = 'primary';
    }

    return html`
      <div class="${componentClassNames}">
        <div class="en-c-dropdown__container">
          <${this.textFieldEl}
            class="en-c-dropdown__input"
            paddingEndOffset=${this.enableClearSelection && !!this.value ? 24 : 0}
            type="text"
            variant="${this.variant}"
            label="${this.label}"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            value="${ifDefined(this.activeElementLabel)}"
            ?hideLabel="${this.hideLabel}"
            ?isReadonly=${this.isReadonly}
            ?isRequired="${this.isRequired}"
            ?isOptional="${this.isOptional}"
            ?isDisabled="${this.isDisabled}"
            ?isError="${this.isError}"
            aria-describedby="${ifDefined(this.ariaDescribedBy)}"
            placeholder="${ifDefined(this.placeholder)}"
            @click=${this.toggleActive}
            @keydown=${this.handleOnKeydown}
            @change=${this.handleOnChange}
            ?isActive="${this.isActive}"
            enableSlotAfterClick=${true}
          >
          ${
            this.slotNotEmpty('dropdown-before')
              ? html`<div slot="before">
                  <slot name="dropdown-before"></slot>
                </div>`
              : html``
          }
            ${
              this.enableClearSelection
                ? html`<div slot="after" style="display:flex;align-items:center">
              ${
                this.value
                  ? html`<${this.buttonEl}  ?hideText=${true} @click=${this.handleCloseIconClick} variant="quaternary">
                <${this.iconCloseEl} slot="after" size="md"></${this.iconCloseEl}>
              </${this.buttonEl}>`
                  : html``
              }
              <${this.iconChevronDownEl} slot="after" class="en-c-dropdown__icon-arrow"></${this.iconChevronDownEl}>
            </div>`
                : html`<${this.iconChevronDownEl} slot="after" class="en-c-dropdown__icon-arrow"></${this.iconChevronDownEl}>`
            }

          </${this.textFieldEl}>
          <${this.dropdownPanelEl} @keydown=${this.handleOnKeydownDropdownPanel} class="${classMap({ 'en-c-dropdown__panel': true, 'en-active': this.isActiveDropdown })}" ?hasHeader=${this.hasSearch} ?hasScroll=${true}>
                  ${this.hasSearch ? html` <${this.searchFormEl} placeholder=${this.searchInputPlaceholder} slot="header" .value=${''} ?isEmpty=${true} @change=${this.handleSearchFormChange} value="${this.searchInputValue}"> </${this.searchFormEl}> ` : html``}
          ${
            this.enableLazyLoading
              ? html`<${this.listEl} @click=${this.toggleActive}>
            ${repeat(
              this.dataSource,
              (row) => `${row.label}_${row.value}`,
              (row) => html`
          <${this.listItemEl} value="${row.value}">${row.label}</${this.listItemEl}>
            `
            )}
          ${this.showLoaderOnLazyLoading && this._setDropdownLoader ? html`<div><${this.loadingIndicatorEl} .small=${this.showSmallLoader} .inverted=${this.showInvertedLoader}></${this.loadingIndicatorEl}></div>` : html``}
          </${this.listEl}>`
              : html`<slot @select=${this.toggleActive}></slot>`
          }
                </${this.dropdownPanelEl}>
          </div>
        ${
          this.fieldNote || this.slotNotEmpty('field-note')
            ? html`
              <slot name="field-note">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} id=${ifDefined(this.ariaDescribedBy)}> ${this.fieldNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
        ${
          (this.errorNote || this.slotNotEmpty('error')) && this.isError
            ? html`
              <slot name="error">
                <${this.fieldNoteEl} ?isDisabled=${this.isDisabled} ?isError=${true}> ${this.errorNote} </${this.fieldNoteEl}>
              </slot>
            `
            : html``
        }
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDropdown.el) === undefined) {
  customElements.define(ENDropdown.el, ENDropdown);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-dropdown': ENDropdown;
  }
}
