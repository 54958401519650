import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ENElement } from '../ENElement';
import styles from './dropdown-panel.scss';

/**
 * @slot - The component content
 */
export class ENDropdownPanel extends ENElement {
  static el = 'en-dropdown-panel';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Adds a max height and scrolling of the panel
   */
  @property({ type: Boolean })
  hasScroll?: boolean;

  /**
   * Changes styling on panel for variant with header
   */
  @property({ type: Boolean })
  hasHeader?: boolean = false;

  /**
   * Changes styling on panel for variant with footer
   */
  @property({ type: Boolean })
  hasFooter?: boolean = false;

  /**
   * Changes tab order of dropdown body
   */
  @property({ type: Number })
  bodyTabIndex?: number;

  render() {
    const componentClassNames = this.componentClassNames('en-c-dropdown-panel', {
      'en-has-header': this.hasHeader === true,
      'en-has-footer': this.hasFooter === true,
      'en-has-scroll': this.hasScroll === true
    });

    return html`
      <div class="${componentClassNames}">
        ${this.slotNotEmpty('header') &&
        html`
          <div class="en-c-dropdown-panel__header">
            <slot name="header"></slot>
          </div>
        `}
        <div class="en-c-dropdown-panel__body" tabindex=${ifDefined(this.bodyTabIndex ? `${this.bodyTabIndex}` : undefined)}>
          <slot></slot>
        </div>
        ${this.slotNotEmpty('footer') &&
        html`
          <div class="en-c-dropdown-panel__footer">
            <slot name="footer"></slot>
          </div>
        `}
      </div>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDropdownPanel.el) === undefined) {
  customElements.define(ENDropdownPanel.el, ENDropdownPanel);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-dropdown-panel': ENDropdownPanel;
  }
}
