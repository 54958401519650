import { TemplateResult, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { map } from 'lit/directives/map.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import timezones from '../../tokens/en/data/timezone.json';
import { ENDropdown } from '../dropdown/dropdown';
import { ENElement } from '../ENElement';
import { ENIconGlobe } from '../icon/icons/globe';
import { ENListItem } from '../list-item/list-item';
import { ENList } from '../list/list';
import { ENSwitch } from '../switch/switch';
import styles from './timezone-dropdown.scss';

/**
 * Component: en-timezone-dropdown
 * @slot - The components content
 */
export class ENTimezoneDropdown extends ENElement {
  static el = 'en-timezone-dropdown';

  private elementMap = register({
    elements: [
      [ENDropdown.el, ENDropdown],
      [ENList.el, ENList],
      [ENListItem.el, ENListItem],
      [ENIconGlobe.el, ENIconGlobe],
      [ENSwitch.el, ENSwitch]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private dropdownEl = unsafeStatic(this.elementMap.get(ENDropdown.el));
  private listEl = unsafeStatic(this.elementMap.get(ENList.el));
  private listItemEl = unsafeStatic(this.elementMap.get(ENListItem.el));
  private switchEl = unsafeStatic(this.elementMap.get(ENSwitch.el));
  private iconGlobeEl = unsafeStatic(this.elementMap.get(ENIconGlobe.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Time zone field label
   */
  @property()
  timezoneFieldLabel?: string = 'Time Zone';

  /**
   * Time zone switch label
   */
  @property()
  timezoneSwitchLabel?: string = 'Select time zone';

  /**
   * Time zone switch label size. Default value is sm.
   * - **sm**: Apply typography en-theme-typography-content-sm
   * - **md**: Apply typography en-theme-typography-content-md
   */
  @property()
  timezoneSwitchLabelSize?: 'sm' | 'md' = 'sm';

  /**
   * If true, timezone switch will be shown, otherwise not
   */
  @property({ type: Boolean })
  showTimezoneSwitch?: boolean = false;

  /**
   * Mutable timezone property. It always points to selected timezone
   */
  @property({ type: String })
  timezone?: string = '';

  /**
   * If true, then set default timezone to client timezone. Default is true.
   */
  @property({ type: Boolean })
  setDefaultTimezoneToBrowserTimezone?: boolean = true;

  /**
   * **Dropdown alignment**
   * - **bottom** Dropdown panel appears on the bottom
   * - **top** Dropdown panel appears on the top
   * If set then open dropdown in that alignment only. Otherwise auto determine position to open
   */
  @property()
  dropdownAlign?: 'bottom' | 'top';

  /**
   * variant (Optional)
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   * - **tertiary** renders the text-field to be used on backgrounds with var(--en-theme-color-background-surface-elevation-2)
   */
  @property()
  variant?: 'primary' | 'secondary' | 'tertiary' = 'primary';

  /**
   * If true dynamically position dropdown panel. Otherwise position according to `align` property value. Default is true.
   */
  @property({ type: Boolean })
  enableDynamicPositioning?: boolean = false;

  /**
   * It takes CSS selector for container consisting dropdown. This container height and bottom is compared with dropdown panel height and bottom and accordingly position is determined. Default value is body. Relevance of setting it only if `enableDynamicPositioning` is set to true.
   */
  @property()
  dropdownPanelContainerSelector?: string = 'body';

  /**
   * This property purpose is use within shadow DOM
   */
  @property()
  dropdownPanelContainerShadowDomElement: HTMLElement;

  /*
   * Set this property if you want to set timezone switch on at initial load. Default is false.
   */
  @property({ type: Boolean })
  setTimezoneSwitchOnAtInitialLoad?: boolean = false;

  /**
   * true if timezone switch is on, else false.
   */
  @state()
  isTimezoneSwitchOn?: boolean = false;

  /**
   * Lifecycle method. Called only once in lifecycle after first update
   * @param _changedProperties
   */
  protected firstUpdated(): void {
    if (this.setTimezoneSwitchOnAtInitialLoad) {
      this.isTimezoneSwitchOn = true;
    }
    if (this.setDefaultTimezoneToBrowserTimezone && !this.timezone) {
      this._setDefaultTimezoneToClientTimezone();
    }
  }

  /**
   * Normalize both timezones in input and compare it. This function is required to check timezone in 2 different browsers.
   */
  private _areTimeZonesEquivalent = (tz1: string, tz2: string) => {
    const resolvedTz1 = new Intl.DateTimeFormat('en-US', { timeZone: tz1 }).resolvedOptions().timeZone;
    const resolvedTz2 = new Intl.DateTimeFormat('en-US', { timeZone: tz2 }).resolvedOptions().timeZone;

    return resolvedTz1 === resolvedTz2;
  };

  /**
   * Set timezone to browser timezone
   * 1. Get client browser timezone
   * 2. Search for direct occurance of client timezone in dropdown list
   * 3. If direct occurance not found. Such case can arise in Mozilla as JSON as timezone names according to Chrome.
   * Such as Asia/Calcutta in Chrome is Asia/Kolkata in Mozilla.
   * In this case call method _areTimeZonesEquivalent to compare 2 timezone and see if they are same.
   */
  private _setDefaultTimezoneToClientTimezone = () => {
    /* 1 */
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /* 2 */
    const clientTimezoneIndexInDropdown = timezones.findIndex((timezone: { label: string; value: string }) => timezone.value === clientTimezone);
    if (clientTimezoneIndexInDropdown > -1) {
      this.timezone = timezones[clientTimezoneIndexInDropdown].value;
      this.dispatch({
        eventName: 'timezoneChange',
        detailObj: {
          timezone: this.timezone
        }
      });
    } else {
      /* 3 */
      for (const timezone of timezones) {
        if (this._areTimeZonesEquivalent(clientTimezone, timezone.value)) {
          this.timezone = timezone.value;
          this.dispatch({
            eventName: 'timezoneChange',
            detailObj: {
              timezone: this.timezone
            }
          });
          break;
        }
      }
    }
  };

  /**
   * Handle timzone change in timezone dropdown field
   * @param evt
   */
  private _handleTimezoneSelection(evt: CustomEvent) {
    const { selectedValue } = evt.detail;
    if (!selectedValue) {
      this.timezone = undefined;
    } else {
      this.timezone = selectedValue;
    }
    this.dispatch({
      eventName: 'timezoneChange',
      detailObj: {
        timezone: this.timezone
      }
    });
  }

  /**
   * Toggle timezone switch
   */
  private _handleTimeZoneSwitchToggle = () => {
    this.isTimezoneSwitchOn = !this.isTimezoneSwitchOn;
    this.dispatch({
      eventName: 'timezoneSwitchToggle',
      detailObj: {
        timezone: this.timezone,
        isTimezoneSwitchOn: this.isTimezoneSwitchOn
      }
    });
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-timezone-dropdown', {});
    const showTimezoneField = !this.showTimezoneSwitch || this.isTimezoneSwitchOn;
    return html`
      <div class="${componentClassNames}">
        ${this.showTimezoneSwitch
          ? html`
        <div class="en-c-timezone-dropdown__switch">
          <label for="time-toggle-switch" class="${classMap({ 'en-c-timezone-dropdown__switch--label': true, 'en-is-md': this.timezoneSwitchLabelSize === 'md' })}">${this.timezoneSwitchLabel}</label>
          <${this.switchEl} id="timezone-toggle-switch" class="en-c-timezone-dropdown__switch--field" .isChecked=${this.isTimezoneSwitchOn} @changed=${this._handleTimeZoneSwitchToggle} label="${this.timezoneSwitchLabel}" variant="primary" name="timezone-toggle"></${this.switchEl}> 
        </div>
        `
          : html``}
        ${showTimezoneField
          ? html`<${this.dropdownEl} .enableDynamicPositioning=${this.enableDynamicPositioning} dropdownPanelContainerSelector="${this.dropdownPanelContainerSelector}" .dropdownPanelContainerShadowDomElement=${this.dropdownPanelContainerShadowDomElement} variant="${this.variant}" label="${this.timezoneFieldLabel}" align=${ifDefined(this.dropdownAlign)} value=${this.timezone ?? ''} @selectValue=${
              this._handleTimezoneSelection
            }>
              <${this.listEl}>${map(
                timezones,
                (timezone: { label: string; value: string }) =>
                  html`<${this.listItemEl} value="${timezone.value}">${timezone.label}</${this.listItemEl}>`
              )}</${this.listEl}>
              <${this.iconGlobeEl} slot="dropdown-before"></${this.iconGlobeEl}>
            </${this.dropdownEl}>`
          : html``}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENTimezoneDropdown.el) === undefined) {
  customElements.define(ENTimezoneDropdown.el, ENTimezoneDropdown);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-timezone-dropdown': ENTimezoneDropdown;
  }
}
