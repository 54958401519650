import { html, unsafeCSS } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import { ENStepperItem } from '../stepper-item/stepper-item';
import styles from './stepper.scss';

/**
 * Component: en-stepper
 * - The stepper is used to help users keep track of their progress and/or indicate where the user is in a multi-step process.
 * @slot - The component content
 */
export class ENStepper extends ENElement {
  static el = 'en-stepper';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Variant
   * - **default** renders a Stepper with a horizontal layout
   * - **vertical** renders a Stepper with a vertical layout
   */
  @property()
  variant?: 'vertical';

  /**
   * Enable click on stepper items by setting true
   */
  @property({ type: Boolean })
  isClick?: boolean;

  /**
   * Query the Stepper item components within the Stepper
   */
  @queryAssignedElements({ flatten: true })
  stepperItems?: Array<ENStepperItem>;

  /**
   * Updated lifecycle
   * 1. If the varient is equal to vertical, set the ENStepperItem's to vertical as well
   * 2. Set the step number for each ENStepperItem
   */
  updated() {
    this.setVerticalItems(); /* 1 */
    this.setStepNumber(); /* 2 */
  }

  /**
   * Set step number
   * 1. Loop through all the ENStepperItem's and set the step number based on the index
   * 2. Check if it's the last item in the array and set the isLast property
   */
  setStepNumber() {
    if (this.stepperItems) {
      this.stepperItems.forEach((item: ENStepperItem, idx: number) => {
        /* 1 */
        item.stepNumber = idx + 1;
        /* 2 */
        if (idx === this.stepperItems.length - 1) {
          item.isLast = true;
        } else {
          item.isLast = false;
        }
      });
    }
  }

  /**
   * Set vertical items
   * 1. If the varient is equal to vertical, set the ENStepperItem's to vertical as well
   */
  setVerticalItems() {
    if (this.stepperItems && this.variant === 'vertical') {
      this.stepperItems.forEach((item: ENStepperItem) => {
        item.variant = 'vertical';
      });
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-stepper', {
      'en-c-stepper--vertical': this.variant === 'vertical'
    });

    return html`
      <ul role="list" class="${componentClassNames}">
        <slot></slot>
      </ul>
    `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENStepper.el) === undefined) {
  customElements.define(ENStepper.el, ENStepper);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-stepper': ENStepper;
  }
}
